// Function to remove emojis without affecting other characters
function filterOutEmojis(value: string): string {
  const stringValue = String(value);

  // Emoji regex to match common emoji ranges
  const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/g;
  return stringValue.replace(emojiRegex, '');  // Replace emojis with an empty string
}

export function InputHandler(variableSetter: any, key: string) {
  return function (e?: any) {
    // Extract the input value
    let inputValue = e?.value || e?.htmlValue || e?.target?.value;

    // Filter out any emojis from the input
    inputValue = filterOutEmojis(inputValue);

    // Update the state with the filtered content
    variableSetter((prevValues: any) => ({
      ...prevValues,
      [key]: inputValue,
    }));
  };
}