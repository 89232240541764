import React, { useEffect, useState,useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Leads from "./leads/Leads";
import Layout from "../components/layout/Layout";
import AxiosService from "../services/AxiosService";
import Dashboard from "./dashboard/Dashboard";
import Agents from "./agents/Agents";
import Mailbox from "./mailbox/Mailbox";
import Inbox from "./mailbox/Inbox";
import ViewProfile from "./profile/ViewProfile";
import ProfileSettings from "./settings/profile/ProfileSettings";
import CompanySettings from "./settings/company/CompanySettings";
import NotificationsSettings from "./settings/notifications/NotificationsSettings";
import MainpageModal from "../components/modals/main/MainpageModal";
import Cookies from "js-cookie";
import GuideTour from "../components/modals/helps/GuideTour";
import Pusher from 'pusher-js';
import { useToast } from "../contexts/ToastContext";
import TeamMembers from "./settings/teammembers/TeamMembers";
import CRM from "./settings/crm/CRM";
import Campaigns from "./campaigns/Campaigns";
import Roles from "./settings/roles/Roles";
import EmailBlueprints from "./settings/email_blueprints/EmailBlurprints";
import Billing from "./settings/billing/Billing";
import moment from "moment";
import ChangePasswordModal from "../components/modals/main/ChangePasswordModal";

const Main: React.FC<any> = ({setLoadingState, axiosService, setAxiosService}) => {
  // let axiosService = useAxios();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [isNPModal, setIsNPModal] = useState(false);
  const [verifyEmailModal, setVerifyEmailModal] = useState(false);
  const [guideTourModal, setGuideTourModal] = useState(false);
  const location = useLocation();
  const currentPage = new URLSearchParams(location.search).get("page") || "dashboard";
  const splitParam = currentPage?.split("/")[1];
  const [isLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>({
    email: "",
    first_login: 1,
    notification_settings: null
  });
  const [credInfo, setCredInfo] = useState<any>({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    img: "",
    current_password: "",
    new_password: "",
    password_confirmation: "",
    company_name: "",
    role: "",
    website: "",
    company_description: "",
    target_description: "",
    calendly_link: "",
    linkedin_url: "",
  });
  const [notifOffset, setNotifOffset] = useState(0);
  const notifLimit = 10;
  const [latestNotifications, setLatestNotifications] = useState<any>({list: [], unread_count: 0 });
  
  const [loadedOlderNotifs, setLoadedOlderNotifs] = useState(false);
  const [loadingOlderNotifs, setLoadingOlderNotifs] = useState(false);
  // const [isOlderNotifsFetched, setIsOlderNotifsFetched] = useState(false);

  const [isResending, setIsResending] = useState(false);
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
  const [refreshAccount, setRefreshAccount] = useState<any>(false);

  const notificationDropdownRef = useRef<HTMLDivElement>(null);

  const getNotifications = () => {

    // console.log("notifOffset::", notifOffset);
    axiosService.api.get(`/api/notifications/list-limit/${notifOffset}/${notifLimit}`)
    .then((response:any) => {

      // console.log("response notifs ::", response.data);
      setLatestNotifications(response.data);
    });
  };

  const [originalCredInfo] = useState<any>({});

  // const updateNotifications:any = () => {
  //   axiosService.api.get('/api/notifications/check/agents').then((firstResponse:any) => {
  //     getNotifications();
  //     setInterval(() => {
  //       axiosService.api.get('/api/notifications/check/agents').then((followingResponse:any) => {
  //         if(followingResponse.data.count > 0){
  //           getNotifications();
  //         }
  //       });
  //     }, 30000);
  //   })
  // }

  const refreshToken = () => {
    axiosService.api.get('/api/user/refresh-token')
      .then((response:any) => {
        let freshService = new AxiosService();
  
        const bearerToken = "Bearer " + response.data.token;
        freshService.api.defaults.headers.common['Authorization'] = bearerToken;
        freshService.setHeaders(bearerToken);
        Cookies.set('bearer_token', bearerToken);

        setAxiosService(freshService);
  
        setIsTokenRefreshed(true);
      })
      .catch((error:any) => {
        console.error("Error refreshing token:", error);
        // Handle token refresh error (e.g., log out the user)
      });
  };
  const getUserData = () => {
    if(axiosService) {
      axiosService.api.get('/api/user/current').then((currentUserData:any) => {
          const pusher = new Pusher('937652a185b2fff494dc', {
            cluster: 'us2',
            forceTLS: true
          });
      
          const channel = pusher.subscribe(`notification-channel-${currentUserData.data.id}`);
      
          channel.bind(`notification-event-${currentUserData.data.id}`, function(data:any) {
            if(data.status){
              getNotifications();
            }
          });

        console.log("CurrentUserData", currentUserData);


        setCredInfo({
          id: currentUserData.data.id,
          firstname: currentUserData.data.accessing_user ? currentUserData.data.accessing_user.firstname : currentUserData.data.firstname,
          lastname: currentUserData.data.accessing_user ? currentUserData.data.accessing_user.lastname : currentUserData.data.lastname,
          email: currentUserData.data.accessing_user ? currentUserData.data.accessing_user.email : currentUserData.data.email,
          phone: currentUserData.data.accessing_user ? currentUserData.data.accessing_user.phone : currentUserData.data.phone,
          current_password: "",
          new_password: "",
          password_confirmation: "",
          company_name: currentUserData.data.company_name || undefined ,
          role: currentUserData.data.role || undefined ,
          website: currentUserData.data.website || undefined ,
          company_description: currentUserData.data.company_description || undefined ,
          target_description: currentUserData.data.target_description || undefined ,
          calendly_link: currentUserData.data.calendly_link || undefined ,
          linkedin_url: currentUserData.data.linkedin_url || undefined,
          profile_picture: currentUserData.data.accessing_user ? currentUserData.data.accessing_user.profile_picture : currentUserData.data.profile_picture,
          accessing_user: currentUserData.data.accessing_user,
          accessed_pages: currentUserData.data.accessed_pages || [],
        });

        setUserDetails(currentUserData.data);

        if(currentUserData.data.password_changed == 0){
          setIsNPModal(currentUserData.data.password_changed == 0);
        }else if(currentUserData.data.email_verified_at == null){
          setVerifyEmailModal(true);
        }else if(currentUserData.data.first_login == 1){
          setGuideTourModal(true);
        }

        // if(currentUserData.data.password_changed == 1){
        //   if(!currentUserData.data.email_verified_at){
        //     setVerifyEmailModal(true);
        //   } else {
        //     if(currentUserData.data.first_login == 1){
        //       setGuideTourModal(true);
        //     }
        //   }
        // }
      }).catch((e:any) => {
        console.log("here");
        sessionStorage.clear();
        Cookies.remove('bearer_token');
        navigate('/');
      });
    }else{
      console.log("empty");
    }
  }

  // const checkFirstTimeUser = () => {
  //   // setGuideTourModal(true);
  //   const isFirstTime = Cookies.get('first_time_login');
  //   console.log('isFirstTime ::: ', isFirstTime);
  //   if (!isFirstTime) {
  //     setGuideTourModal(true);
  //     Cookies.set('first_time_login', 'no', { expires: 365 }); // Set cookie for 1 year
  //   }
  // }

  const handleShowOlderNotifs = () => {
    setLoadingOlderNotifs(true);
    const newOffset = notifOffset + notifLimit;
    axiosService.api.get(`/api/notifications/list-limit/${newOffset}/${notifLimit}`)
    .then((response:any) => {
      // console.log("response notifs ::", response.data);
      setLatestNotifications({ list: [...latestNotifications.list, ...response.data.list], unread_count: response.data.unread_count });
      setNotifOffset(newOffset); // Update offset for next load more
      setLoadedOlderNotifs(true);
      setLoadingOlderNotifs(false);
    })
    .catch((error:any) => {
      console.error("Error fetching notifications:", error);
      setLoadedOlderNotifs(false);
      setLoadingOlderNotifs(false);
      // Handle error
    });
  };

  const handleNotifScroll = () =>{
    const notificationDropdown = notificationDropdownRef.current;
    if (!notificationDropdown) return;
    // console.log(' list length ::: ', latestNotifications.list.length)
    if (notificationDropdown.scrollTop + notificationDropdown.clientHeight >= notificationDropdown.scrollHeight) {
      if (loadedOlderNotifs && latestNotifications.list.length !== latestNotifications.unread_count) {
        handleShowOlderNotifs();
      }
    }
  }
  
  useEffect(() => {
    // Ybug script
    const ybugScript = document.createElement('script');
    ybugScript.type = 'text/javascript';
    ybugScript.async = true;
    ybugScript.src = 'https://widget.ybug.io/button/8z60k7wwxfyk6yd9cqz4.js';
    document.body.appendChild(ybugScript);

    // Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5JG99ZW5');
    `;
    document.head.appendChild(gtmScript);

    // Google Tag Manager noscript
    const gtmNoscript = document.createElement('noscript');
    gtmNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5JG99ZW5"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(gtmNoscript);
  }, []);



  const renderPage = () => {
    switch (currentPage) {
      case 'dashboard': 
        return <Dashboard axiosService={axiosService} setLoadingState={setLoadingState} />
      case 'leads': 
        return <Leads setRefreshAccount={setRefreshAccount} axiosService={axiosService} setLoadingState={setLoadingState} />
      case 'campaigns': 
        return <Agents axiosService={axiosService} setLoadingState={setLoadingState} />
        case 'reporting': 
        return <Campaigns setLoadingState={setLoadingState} />
      case 'mailbox': 
        return <Mailbox axiosService={axiosService} setLoadingState={setLoadingState}  />
      case 'emails': 
        return <Inbox axiosService={axiosService} setLoadingState={setLoadingState}  />
      case 'settings/profile': 
        return <ProfileSettings axiosService={axiosService} setLoadingState={setLoadingState} credInfo={credInfo} setCredInfo ={setCredInfo} originalCredInfo={originalCredInfo}  />
      case 'settings/company': 
        return <CompanySettings axiosService={axiosService} setLoadingState={setLoadingState} credInfo={credInfo} setCredInfo ={setCredInfo} originalCredInfo={originalCredInfo}   />
      case 'settings/notifications': 
        return <NotificationsSettings axiosService={axiosService} setLoadingState={setLoadingState} credInfo={credInfo} setCredInfo ={setCredInfo} originalCredInfo={originalCredInfo} currentPage={splitParam} userDetails={userDetails} />
      case 'view-profile': 
        return <ViewProfile axiosService={axiosService} setLoadingState={setLoadingState} credInfo={credInfo} />
      case 'settings/team-members': 
        return <TeamMembers setLoadingState={setLoadingState}  />
      case 'settings/crm': 
        return <CRM setLoadingState={setLoadingState}  />
      case 'settings/roles': 
        return <Roles setLoadingState={setLoadingState}  />
      case 'settings/billing': 
        return <Billing setLoadingState={setLoadingState}  />
      case 'settings/email-blueprints':
        return <EmailBlueprints axiosService={axiosService} setLoadingState={setLoadingState}   />
      default:
        return <Dashboard axiosService={axiosService} setLoadingState={setLoadingState} />
    }
  };

  const noDisplay = () => {
    return (
      <div className="h-full w-full flex justify-center items-center">
        Loading...
      </div>
    );
  }

  const resendLink = () => {
    setIsResending(true);
    axiosService.api.get('/api/user/resend/verification')
      .then((response:any) => {
        setIsResending(false);
        if(response.data.status){
          setVerifyEmailModal(false);
          if(userDetails.first_login == 1){
            setGuideTourModal(true);
          }
          showToast({
            severity: 'success',
            summary: 'Sent!',
            detail: response.data.message
          });
        }
      }).catch((e:any) => {
        setIsResending(false);
        showToast({
          severity: 'error',
          summary: 'Failed!',
          detail: e.response?.data.message || e.response?.data.error,
        });
      });
  };

  const checkBillingHistory = () => {
    axiosService.api.get('/api/payment-method/billing-history').then((response:any) => {
      if(response.data.length == 0){
        navigate('/subscription');
      }
    });
  };

  const onPasswordChange = (passwordDetails:any) => {
    axiosService.api.post('/api/user/new-password', passwordDetails)
    .then((response:any) => {
      console.log("response::", response);
      setIsNPModal(false);
      showToast({
        severity: 'success',
        summary: 'New Password!',
        detail: response.data.message
      });

      getUserData();
      // setGuideTourModal(true);

      // if(!userDetails.email_verified_at){
      //     setVerifyEmailModal(true);
      //   } else {
      //     if(userDetails.first_login == 1){
      //       setGuideTourModal(true);
      //     }
      //   }
    }).catch((error:any) => {
      setLoadingState(false);
      console.log("message::", error.response)
      showToast({
        severity: 'error',
        summary: 'Invalid Registration!',
        detail: error.response.data.message
      });
      console.error('Error fetching data:', error);
    });
  };

  useEffect(() => {
    if(isTokenRefreshed){
      setLoadingState(false);
      getUserData();
      getNotifications();
      checkBillingHistory();
    }
  }, [isTokenRefreshed]);

  useEffect(() => {
    refreshToken();
  }, []);
  
  return(
    <>
      {isLoading && (
        <div className='absolute w-full h-full bg-gray-100 bg-opacity-80 z-[999] flex justify-center items-center'>
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <Layout 
        children={(isTokenRefreshed) ? renderPage() : noDisplay()} 
        setLoadingState={setLoadingState} 
        latestNotifications={latestNotifications} 
        axiosService={axiosService} 
        loadedOlderNotifs={loadedOlderNotifs} 
        handleShowOlderNotifs={handleShowOlderNotifs} 
        handleNotifScroll={handleNotifScroll} 
        loadingOlderNotifs={loadingOlderNotifs} 
        notificationDropdownRef={notificationDropdownRef} 
        refreshAccount={refreshAccount}
        setRefreshAccount={setRefreshAccount}
        credInfo={credInfo}
      />
      <MainpageModal visible={verifyEmailModal} data={userDetails} onHide={() => {
          setVerifyEmailModal(false)
          if(userDetails.first_login == 1){
            setGuideTourModal(true);
          }
        }}
        resendLink={resendLink}
        isResending={isResending}
      />
      {/* onHide={() => setVerifyEmailModal(false)} */}
      <GuideTour visible={guideTourModal} onHide={() => setGuideTourModal(false)} />
      <ChangePasswordModal onSubmit={onPasswordChange} onHide={() => setIsNPModal(false)} visible={isNPModal} userDetails={userDetails} />
    </>
  )
};

export default Main;
