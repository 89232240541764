interface ExpandedAgentsProps {
  rowData: any; 
  statusFunction: (status: string) => JSX.Element; 
  listFunction: (keywords: string[], show_all: boolean) => JSX.Element;
}

const ExpandedAgents: React.FC<ExpandedAgentsProps> = ({ rowData, statusFunction, listFunction }) => {
  console.log('rowData :: ', rowData)
  
  return(
    <>
      <div className="grid grid-cols-3 gap-5">
        <div className="flex flex-col gap-5 ">
          <div className="flex flex-col gap-1">
            <label className="text-label">Agent Name</label>
            <p>{rowData.name}</p>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-label">Customer Profile</label>
            <p>{rowData.customer_profile}</p>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-label">Date</label>
            <p>{rowData.date}</p>
          </div>
          <div className="flex flex-col gap-1 w-1/3">
            <label className="text-label">Status</label>
            <p>{statusFunction(rowData.status)}</p>
          </div>
        </div>
        <div className="flex flex-col gap-5 ">
          <div className="flex flex-col gap-1">
            <label className="text-label">Target Keywords</label>
            <p>{listFunction(rowData.target_keywords,true)}</p>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-label">Target Countries</label>
            <p>{listFunction(rowData.target_countries,true)}</p>
          </div>
        </div>
        <div className="flex flex-col gap-5 ">
          <div className="flex flex-col gap-1">
            <label className="text-label">Total Leads Discovered</label>
            <p>{rowData.total_leads_discovered}</p>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-label">Total Companies Processed</label>
            <p>{rowData.total_leads_processes}</p>
          </div>
        </div>
      </div>

    </>
  )
};

export default ExpandedAgents;
