import { Button } from "primereact/button";
import { AddAgentsGraphics } from "../../components/Icons/ImgPath";
import { useEffect, useState } from "react";
import ExpandedAgents from "./ExpandedAgents";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import OnboardingSummaryModal from "../../components/modals/onboarding/OnboardingSummaryModal";
import AgentAddMoreLeadsModal from "../../components/modals/agents/AgentAddMoreLeadsModal";
import AgentImportCsvModal from "../../components/modals/agents/AgentImportCsvModal";
import AgentDeleteModal from "../../components/modals/agents/AgentDeleteModal";
import { InputSwitch } from 'primereact/inputswitch';
import LazyTable from "../../components/tables/LazyTable";
import { useToast } from "../../contexts/ToastContext";
import Inputs from "../../components/forms/Inputs";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";
import { AgentProps } from "../../interface/datatypes";
import _ from 'lodash';        
import { useSubscriptionRestrictions } from "../../contexts/SubscriptionRestrictions";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const Agents: React.FC<any> = ({ axiosService, setLoadingState }) => {
  const navigate = useNavigate();
  const [askModal, setAskModal] = useState(false);
  const [isEdit, setIsEdit] = useState<any>(false);
  const [defaultPrompt, setDefaultPrompt] = useState<any>();
  const [isGenerated, setIsGenerated] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingPitch, setIsLoadingPitch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useToast();
  const [api] = useState<any>('/api/agents/paginate');
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [values, setValues] = useState([]);
  const [values1, setValues1] = useState(null);
  const [values2, setValues2] = useState(null);
  // const [clickedAgent] = useState(0);
  const [selectedCountries] = useState<any[]>([]);
  const [countries, setCountries] = useState<any>([]);
  const { fetchRestrictions } = useSubscriptionRestrictions();
  const [addCampaignLoading, setAddCampaignLoading] = useState(false);
  const [importCsvLoading, setImportCsvLoading] = useState(false);
  const [csvFile, setCsvFile] = useState<any>(null);

  const [miscLinks, setMiscLinks] = useState<any[]>(
    [{ description: "", link: "" }]
  )
  const initCountries = () => {
    axiosService.api.get(`/api/countries/get-countries`)
    .then((response: any) => {
      const countriesData = response.data;
      const countriesFormatted = countriesData.map((country: any) => ({
        name: country.name,
        value: country.id,
        flag: country.flag
      }));
      setCountries(countriesFormatted);
    })
    .catch((error: any) => {
      console.error("Error fetching countries:", error);
    });
  };
  const [filterValues, setFilterValues] = useState<any>({
    target_countries: [],
    status: 'All',
    total_leads_discovered: [0, 999],
    total_leads_processes: [0, 999],
    date_range: null
  });

  const countryTemplate = (option: any) => {
    return (
      <div className="flex items-center">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`mr-2 flag ${option.flag ? `flag-${option.flag.toLowerCase()}` : ''}`}
          style={{ width: '18px' }}
        />
        <div>{option.name}</div>
      </div>
    );
  };
  
  const panelFooterTemplate = () => {
    const length = selectedCountries ? selectedCountries.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? 's' : ''} selected.
      </div>
    );
  };

  const [GPTPromptExamples, setGPTPromptExamples] = useState([]);
  // const [agentTemplate] = useState<any>({
  //   template_type: 'B2B Sales',
  //   template_tone: 'Company Introduction',
  //   gpt_prompt_example: null
  // });
  // const saveAgentTemplate = () => {
  //   hideSettingsModal();
  //   axiosService.api.patch(`/api/agents/settings/${clickedAgent}`, agentTemplate)
  //     .then((patchData:any) => {
  //       if(patchData.data.status){
  //         showToast({
  //           severity: 'success',
  //           summary: 'Success!',
  //           detail: patchData.data.message
  //         });
  //       }
  //     });
  // };

  const leadsFilter:any = (
    <>
      <div>
        <MultiSelect 
          autoOptionFocus={false} 
          focusOnHover={false} 
          selectOnFocus={false} 
          value={filterValues.target_countries} options={countries} 
          onChange={(e: MultiSelectChangeEvent) => {
            setFilterValues((prevValues:any) => ({
              ...prevValues,
              target_countries: e.value
            }));
          }} 
          optionLabel="name" 
          filter 
          placeholder="Select Target Countries" 
          itemTemplate={countryTemplate} 
          panelFooterTemplate={panelFooterTemplate} 
          className="w-full md:w-20rem" 
          display="chip" 
        />
      </div>
      <div>
        <Inputs 
          value={filterValues.status} 
          options={['All', 'Pending', 'In-Progress', 'Completed']} 
          label={"Status"} 
          type={"dropdown"} 
          onChange={(e:any) => (setFilterValues((prevValues:any) => ({...prevValues, status: e.value})))}
        />
      </div>
      {/* <div>
        <Inputs 
          value={filterValues.total_leads_discovered} 
          label={"Total Leads Discovered"} 
          type={"slider"} 
          onChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, total_leads_discovered: e.value}))}}
          inputOnChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, total_leads_discovered: e.target.value.split(',')}))}}
        />
      </div> */}
      {/* <div>
        <Inputs 
          value={filterValues.total_leads_processes} 
          label={"Total Leads Processes"} 
          type={"slider"} 
          onChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, total_leads_processes: e.value}))}}
          inputOnChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, total_leads_processes: e.target.value.split(',')}))}}
        />
      </div> */}
      <div>
        <Inputs type="date-range" 
          onChange={(e:any) => {
            setFilterValues((prevValues:any) => ({
              ...prevValues, 
              date_range: e.value
            }));
          }} 
          value={filterValues.date_range} 
          label={"Date"} 
        />
      </div>
    </>
  );

  const [modalTexts, setModalTexts] = useState<any>({
    header: "Create Agent",
    button: "Create",
  });
  const statusTemplate = (status: string) => {
    return <span className={`${status.toLowerCase() == 'completed' ? 'text-green' : 'text-orange-500'} w-[130px] justify-center bg-lightgray py-1 px-2 rounded-md flex items-center gap-2`}>
      <i className="pi pi-circle-fill"></i>{status}
    </span>
  };

  const listTemplate = (keywords: string[], show_all: boolean) => {
    if (show_all) {
      return (
        <span className="">{keywords.join('; ')}</span>
      );
    } else {
      const displayedKeywords = keywords.slice(0, 4).join('; ');
      return (
        <span className="">
          {displayedKeywords}
          {keywords.length > 4 && '...'} 
        </span>
      );
    }
  }
  const handleDataConvert = (data: any, setTableData: Function) => {
    setTableData(data.map((e:any) => ({
      id: e.id,
      name: e.name,
      company_name: e.company_name,
      website: e.website,
      offers: e.offers,
      description: e.description,
      target_description: e.target_description,
      short_target_keywords: e.target_keywords.slice(0, 4).join('; ') + (e.target_keywords.length > 4 ? '...' : ''),
      target_keywords: e.target_keywords,
      target_countries: e.target_countries,
      status: e.status,
      total_leads_discovered: e.leads_counts_discovered,
      ai_confident_leads_counts_discovered: e.ai_confident_leads_counts_discovered,
      total_leads_processes: e.leads_counts_processes,
      email: e.email,
      phone: e.phone,
      full_name: e.full_name,
      calendly_link: e.calendly_link,
      role: e.role,
      linkedin_url: e.linkedin_url,
      customer_profile: e.customer_profile ?? '---',
      is_active: e.is_active,
      created_at: new Date(e.created_at).toLocaleDateString('en-GB'),
    })));
  };
  

  const activeTemplate = (active: any, rowData:any) => {
    const complementActive = active == 1 ? 0 : 1;
    return <InputSwitch checked={active == 1} 
      onChange={() => {
        axiosService.api.get(`/api/agents/active/${rowData.id}/${complementActive}`).then((retValue:any) => {
          setRefreshTable(true);
        });
      }} />
  };

  const agentsTableColumn = [
    { field: 'name', header: 'Campaign Name', has_expander: true},
    { field: 'short_target_keywords', header: 'Target Keywords' },
    { field: 'target_countries', header: 'Target Country', template: listTemplate, hasTemplate: true },
    { field: 'status', header: 'Status', template: statusTemplate, hasTemplate: true  },
    { field: 'total_leads_discovered', header: 'Total Leads Discovered' },
    { field: 'ai_confident_leads_counts_discovered', header: 'AI Relevant Leads Discovered' },
    { field: 'total_leads_processes', header: 'Total Companies Processed' },
    { field: 'customer_profile', header: 'Customer Profile' },
    { field: 'created_at', header: 'Date' },
    { field: 'is_active', header: 'Active', template: activeTemplate, hasTemplate: true },
  ];

  const [initialAgentState] = useState<AgentProps>({
    id: '',
    website: "https://",
    company_name: "",
    agent_name: "My First Agent",
    description: "",
    target_description: "",
    target_keywords: [],
    target_countries: [],
    target_language: [],
    offers: "",
    tools: {
      google_search: false,
      google_places: false,
      linkedin: false,
    },
    suggested_sales_profile: "",
    suggested_pitch: "",
    suggested_keywords:[],
    credits: 0,
    ai_personality_id: 0,
    phone: '',
    email: '',
    address: '',
    role: '',
    calendly_link: '',
    linkedin_url: '',
    full_name: '',
    existing_agent:'',
    miscellaneous_links: [],
    negative_keywords: [],
  });
  const [agentData, setAgentData] = useState<AgentProps>({
    id: '',
    website: "https://",
    company_name: "",
    full_name: "",
    agent_name: "My First Agent",
    description: "",
    target_description: "",
    target_keywords: [],
    target_countries: [],
    target_language: [],
    offers: "",
    tools: {
      google_search: false,
      google_places: false,
      linkedin: false,
    },
    suggested_sales_profile: "",
    suggested_keywords:[],
    suggested_pitch:"",
    credits: 0,
    ai_personality_id: 0,
    phone: '',
    email: '',
    address: '',
    role: '',
    calendly_link: '',
    linkedin_url: '',
    existing_agent:'',

    miscellaneous_links: [],
    negative_keywords: [],
    search_scope: '',
  });
  
  const handleGenerating = async (event: any) => {
    setIsLoading(true);
    setIsLoadingProfile(true);
    setIsLoadingPitch(true);
  
    let dataToSend:any = {
      company_name: agentData.company_name,
      description: agentData.description,
      target_description: agentData.target_description,
      target_keywords: agentData.target_keywords,
      target_countries: agentData.target_countries,
      target_language: agentData.target_language,
      website: agentData.website,
      fullname: agentData.full_name,
      email: agentData.email,
      phone: agentData.phone,
      role: agentData.role,
      calendly_link: agentData.calendly_link,
      linkedin_url: agentData.linkedin_url,
      offers: agentData.offers,
      miscellaneous_links: agentData.miscellaneous_links,
      negative_keywords: agentData.negative_keywords,
      suggested_sales_profile: "",
      suggested_keywords:[],
      suggested_pitch:"",
    };

    let sampleCopy = JSON.parse(JSON.stringify(dataToSend));

    Object.keys(sampleCopy).forEach(key => {
      if(sampleCopy[key] == null || sampleCopy[key].length == 0){
        delete(sampleCopy[key]);
      }
    });

    let suggestionDetails = {
      details: sampleCopy,
      prompts: {
        suggested_sales_profile: defaultPrompt.suggested_sales_profile.map((k:any) => (k.value)),
        suggested_pitch: defaultPrompt.suggested_pitch.map((k:any) => (k.value)),
        suggested_keywords: defaultPrompt.suggested_keywords.map((k:any) => (k.value))
      },
      selected_keys: defaultPrompt.include_keys
    };

    try {
      axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_sales_profile', suggestionDetails)
      .then((bpData:any) => {
        suggestionDetails.details.suggested_sales_profile = bpData.data.suggestion;
        setValues(bpData.data.suggestion);
        setAgentData((prevValues: AgentProps) => ({
          ...prevValues,
          suggested_sales_profile: bpData.data.suggestion
        }));
        setIsLoadingProfile(false);
        axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_pitch', suggestionDetails)
        .then((pData:any) => {
          suggestionDetails.details.suggested_pitch = pData.data.suggestion;
          setValues2(pData.data.suggestion);
          setAgentData((prevValues: AgentProps) => ({
            ...prevValues,
            suggested_pitch: pData.data.suggestion,
          }));
          setIsLoadingPitch(false);
          axiosService.api.post('/api/gpt-prompt/dynamic/suggest/suggested_keywords', suggestionDetails)
          .then((kData:any) => {
            const newKeywords = kData.data.suggestion.flat();
            setValues1(newKeywords);
            setAgentData((prevValues: AgentProps) => {
              const existingKeywords = prevValues.target_keywords;
              const uniqueNewKeywords = newKeywords.filter((keyword:any) => !existingKeywords.includes(keyword));
        
              return {
                ...prevValues,
                target_keywords: [...existingKeywords, ...uniqueNewKeywords],
                suggested_keywords: newKeywords,
              };
            });
            
            setIsLoading(false);
            setIsGenerated(true);
          })
        });
      });
    } catch (error) {
      setIsLoading(false);
      setIsGenerated(false);
      setIsLoadingProfile(false);
      setIsLoadingPitch(false);
    }
  };

  const [agentModal, setAgentModal] = useState(false);
  const [agentModalKey, setAgentModalKey] = useState('agentModalk');
  const [agentModalType, setAgentModalType] = useState('');
  const handleAgentModal = (type: string) => {
    setIsGenerated(false);
    setAgentModalKey(_.uniqueId());
    setModalTexts({
      header: "Create Agent",
      button: "Create"
    });
    if(type == "edit"){
      setModalTexts({
        header: "Update Campaign",
        button: "Update"
      });
    }else if(type == "add"){
      console.log('hit')
      initData();
    }
    
    setAgentModal(true);
    setAgentModalType(type);
    setCsvFile(null);
    
  }

  const handleAgentModalClose = () => {
    setIsEdit(false);
    setAgentData(initialAgentState);
    setAgentModal(false);
    setAgentModalType('')
  }

  // const handleAgentData = (e:any) => {
  //   const { name, value } = e.target;
  //   setAgentData((prevState:AgentProps) => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  // };

  const handleAgentSubmit = () => {
    let requestPromise;

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });

    setIsSubmitting(true);

    setModalTexts((prevModalTexts: any) => ({
      ...prevModalTexts,
      button: (agentModalType == "add") ? "Creating..." : "Updating..."
    }));

    console.log('agentData :: ', agentData)

    
    if (agentModalType === "add") {
      requestPromise = axiosService.api.post('/api/agents/add', agentData);
    } else if (agentModalType === "edit") {
      if(csvFile===null){
        requestPromise = axiosService.api.patch('/api/agents/update/' + agentData.id, agentData);
      }else if( csvFile.length===0 ){
        requestPromise = axiosService.api.patch('/api/agents/update/' + agentData.id, agentData);
      }else{
        const formData_wjqvwij4 = new FormData();
        formData_wjqvwij4.append("file", csvFile[0]);
        formData_wjqvwij4.append("ent_info", JSON.stringify( agentData ) );
        requestPromise = axiosService.api.post('/api/agents/update/' + agentData.id, formData_wjqvwij4, { headers: { 'Content-Type': 'multipart/form-data' } });
      }
    } else {
      return;
    }

    requestPromise.then((response: any) => {
      setTimeout(() => {
        showToast({ severity: 'success', summary: 'Success!', detail: response.data.message, });
        handleAgentModalClose();
        setRefreshTable(true);
        setIsSubmitting(false);
      },500);
    })
    .catch((error: any) => {
      setIsSubmitting(false);
      showToast({
        summary: 'Insufficient Credits',
        severity: 'error',
        detail: error.response.data.message,
      });
    });
  }
  

  const deleteAgent = (id: number) => {
    axiosService.api.delete('/api/agents/delete/' + id)
    .then((response:any) => {
      handleAgentModalClose();
      setRefreshTable(true);
    })
    .catch((error:any) => {
      console.error('Error fetching data:', error);
    });
  }

  const confirmAgentDelete = (id: number) => {
    confirmDialog({
      message: 'Do you want to delete this campaign?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteAgent(id)
    });
  };

  // const [settingsModal, setSettingsModal] = useState(false);
  // const hideSettingsModal = () => {
  //   setSettingsModal(false);
  // };
  
  const [isAgentAddMoreLeadsModalOpen, setIsAgentAddMoreLeadsModalOpen] = useState(false);
  const [agentAddMoreLeadsModalKey, setAgentAddMoreLeadsModalKey] = useState('kAgentAddMoreLeadsModalKey');
  const [agentAddMoreLeadsModalAgentId, setAgentAddMoreLeadsModalAgentId] = useState();

  const [isAgentImportCsvModalOpen, setIsAgentImportCsvModalOpen] = useState(false);
  const [agentImportCsvModalKey, setAgentImportCsvModalKey] = useState('kAgentImportCsvModalKey');

  const actionsClicked = async (data: any, type: string) => {
    setLoadingState(true)
    if(type === 'edit'){
      const [userDataResponse,agentResponse] = await Promise.all([

        axiosService.api.get('/api/user/current'),
        axiosService.api.get('/api/agents/get/' + data),
      ]);

      setLoadingState(false);
      setIsGenerated(true);
      
      const userData = userDataResponse.data || {};
      const agentData = agentResponse.data || {};
      
      const agentCountries = countries.filter((k:any) => (agentData.target_countries.includes(k.name))).map((k:any) => (k.value));

      setIsEdit(true);
        setAgentData({
          id: agentData.id,
          website: checkValue(agentData.website) || '',
          company_name: checkValue(agentData.company_name) || '',
          agent_name: agentData.name,
          description: checkValue(agentData.description) || '',
          target_description: checkValue(agentData.target_description) || '',
          target_keywords: checkValue(agentData.target_keywords) || [],
          target_language: checkValue(agentData.target_language) || '',
          target_countries: agentCountries,
          offers: checkValue(agentData.offers) || '',
          tools: {
            google_search: false,
            google_places: false,
            linkedin: false,
          },
          suggested_sales_profile: checkValue(agentData.suggested_sales_profile) || '',
          suggested_pitch: checkValue(agentData.suggested_pitch) || '',
          suggested_keywords: checkValue(agentData.suggested_keywords) || [],
          email: checkValue(agentData.email) || '',
          phone: checkValue(userData.phone) || '',
          full_name: checkValue(agentData.full_name) || '',
          address:checkValue(userData.address) || '',
          calendly_link: checkValue(agentData.calendly_link) || '',
          role: checkValue(agentData.role) || '',
          linkedin_url: checkValue(agentData.linkedin_url) || '',
          credits: checkValue(agentData.credits) || 0,
          ai_personality_id: checkValue(agentData.ai_personality_id) || 0,
          miscellaneous_links: agentData.miscellaneous_links || [],
          negative_keywords: checkValue(agentData.negative_keywords) || [],
          search_scope: checkValue(agentData.search_scope) || '',
          status: agentData.status || '',
          is_csv: agentData.is_csv || 0,
          has_keyword_search: agentData.has_keyword_search.toString() || '0',
          csv_import: agentData.csv_import,
        });
        
      
      /*  
          if( agentData.is_csv ){
          
            setAgentImportCsvModalKey( _.uniqueId() );
            setIsAgentImportCsvModalOpen( true );      
          
            return;
          }
      */

      handleAgentModal('edit');        
        
        
      if(agentData.miscellaneous_links && agentData.miscellaneous_links.length>0){
        // console.log('true ::: ', response.data.miscellaneous_links)
        setMiscLinks(agentData.miscellaneous_links)
      }else{
        setMiscLinks([{ description: "", link: "" }])
      }
    } else if(type==='trash'){
      setLoadingState(false);
      confirmAgentDelete(data);
    } else if(type==='settings'){
      // setAgentTemplate({
      //   template_type: 'B2B Sales',
      //   template_tone: 'Company Introduction',
      //   gpt_prompt_example: null
      // });
      // setClickedAgent(data);
      // axiosService.api.get('/api/agents/get/' + data)
      // .then((agentData:any) => {
      //   setLoadingState(false);
      //   if(agentData.data.settings == null){
      //     showToast({
      //       severity: 'warn',
      //       summary: 'New Agent Template',
      //       detail: "This agent does not have an existing template yet",
      //     });
      //   }else{
      //     setAgentTemplate(agentData.data.settings);
      //   }
      //   setSettingsModal(true);
      // }).catch((error:any) => {
      //   setLoadingState(false);
      // });
    } else if( type==='play' ){
      setAgentAddMoreLeadsModalAgentId( data );
      setAgentAddMoreLeadsModalKey( _.uniqueId() );
      setIsAgentAddMoreLeadsModalOpen( true );
    } else if( type==='delete2' ){
      setLoadingState(false);
      AgentDeleteModal( axiosService, setRefreshTable, data );
    }    
  }

  const handleBulkDelete = (selectedData: any) => {
    setLoadingState(true);
    const ids = selectedData.map((e: any) => e.id);
    axiosService.api.post('/api/agents/bulk-delete', {ids: ids})
    .then((response:any) => {
      setLoadingState(false);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
      setRefreshTable(true);
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });

  }

  const getDefaultPrompt = () => {
    axiosService.api.get('/api/gpt-prompt/default-prompt')
      .then((response:any) => {
        setDefaultPrompt(response.data);
      });
  };

  const initGPTPrompts = () => {
    console.log('GPTPromptExamples ::: ', GPTPromptExamples);
    axiosService.api.get('/api/agents/gpt-prompts')
    .then((response:any) => {
      setGPTPromptExamples(response.data.map((k:any) => {
        return {
          label: k.prompt,
          value: k.id
        }
      }));
    });
  };

  const checkValue = (value:any, defaultValue = '') => (value !== undefined && value !== null) ? value : defaultValue;

  // const getLatestAgent = () => {
  //   axiosService.api.get('/api/agents/latest')
  //     .then((response:any) => {
  //       if(response.data.latest != null){
  //         setAgentData({
  //           id: '',
  //           website: response.data.latest.website,
  //           company_name: response.data.latest.company_name,
  //           agent_name: response.data.latest.name,
  //           description: response.data.latest.description,
  //           target_description: response.data.latest.target_description,
  //           target_keywords: response.data.latest.target_keywords,
  //           target_countries: [],
  //           offers: response.data.latest.offers,
  //           tools: {
  //             google_search: false,
  //             google_places: false,
  //             linkedin: false,
  //           },
  //           suggested_sales_profile: "",
  //           suggested_pitch: "",
  //           suggested_keywords:[],
  //           credits: 25,
  //           ai_personality_id: response.data.latest.ai_personality_id,
  //           full_name: checkValue(response.data.latest.full_name) || '',
  //           phone: checkValue(response.data.latest.phone) || '',
  //           email: checkValue(response.data.latest.email ) || '',
  //           address: checkValue(response.data.latest.address ) || '',
  //           role: checkValue(response.data.latest.role ) || '',
  //           calendly_link: checkValue(response.data.latest.calendly_link ) || '',
  //           linkedin_url: checkValue(response.data.latest.linkedin_url ) || '',
  //           miscellaneous_links: checkValue(response.data.latest.miscellaneous_links ) || [],
  //           negative_keywords: checkValue(response.data.latest.negative_keywords ) || [],
  //         });
  //       }
  //     });
  // }

  const initData = async () => {
    try {
      const [userDataResponse,userCompanyResponse, agentsLatestResponse] = await Promise.all([

        axiosService.api.get('/api/user/current'),
        axiosService.api.get('/api/company/get'),
        axiosService.api.get('/api/agents/latest'),
      ]);
  
      const userData = userDataResponse.data || {};
      const userCompanyData = userCompanyResponse.data.company || {};
      const agentsLatestData = agentsLatestResponse.data.latest || {};

      // console.log('userCompanyData ::: ', userCompanyData);
      // console.log('userData ::: ', userData);
      // console.log('agentsLatestData ::: ', agentsLatestData);
  
      setAgentData((prevState:AgentProps) => ({
        ...prevState,
        full_name: checkValue(userData.name)|| '',
        email: checkValue(userData.email) || '',
        phone: checkValue(userData.phone) || '',
        website: checkValue(userCompanyData.website) || '',
        company_name: checkValue(userCompanyData.company_name) || '',
        calendly_link: checkValue(userCompanyData.calendly_link) || '',
        role: checkValue(userCompanyData.role) || '',
        linkedin_url: checkValue(userCompanyData.linkedin_url) || '',
        description: checkValue(userCompanyData.business_description) || '',
        target_description: checkValue(userCompanyData.target_description) || '',
        target_keywords: checkValue(agentsLatestData.target_keywords) || [],
        target_countries: checkValue(agentsLatestData.target_countries) || [],
        target_language: checkValue(agentsLatestData.target_language) || 'English',
        offers: checkValue(agentsLatestData.offers) || '',
        credits: agentsLatestData.credits || 0,
        ai_personality_id: agentsLatestData.ai_personality_id || 0,
        address: agentsLatestData.address || '',
        miscellaneous_links: agentsLatestData.miscellaneous_links || [],
        negative_keywords: checkValue(agentsLatestData.negative_keywords) || [],
        // target_contact_person_seniority_levels: agentsLatestData.target_contact_person_seniority_levels || [],
        target_contact_person_management_levels: agentsLatestData.target_contact_person_management_levels || [],
        target_contact_person_departments: agentsLatestData.target_contact_person_departments || [],
        search_scope: agentsLatestData.search_scope || '',
        suggested_keywords: agentsLatestData.suggested_keywords || [],
      }));

      setLoadingState(false);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const navigateOnboarding = () => {
    setAddCampaignLoading(false);
    navigate('/onboarding')
  }

  const errorCallback = () => {
    setAddCampaignLoading(false);
  }
  
  const importCsv = () => {    
    setIsEdit(false);
    setAgentImportCsvModalKey( _.uniqueId() );
    setIsAgentImportCsvModalOpen( true );
  }  

  const handleAddCampaign = () => {
    setAddCampaignLoading(true);
    fetchRestrictions('agent', navigateOnboarding, errorCallback);
  }

  useEffect(() => {
    getDefaultPrompt();
    initCountries();
    initGPTPrompts();
    // getLatestAgent();
  }, []);
  
  return (
    <div id="agents" className="flex flex-col gap-3">
      <Dialog header="Add Agent" closable={false} visible={askModal} onHide={() => {setAskModal(false)}}
            pt={{header:{className:'text-center', style:{borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}, 
            content:{style:{borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}}}} 
            dismissableMask={true}
            style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div className="flex flex-col w-full justify-center">

          <img className="mx-auto w-[320px] max-w-[320px] flex align-center" src={AddAgentsGraphics} alt="Login Logo"/>
          <h3 className="my-5 text-center text-[16px] font-regular">Enter necessary information to add agent</h3>
          <div className="flex flex-row justify-between">
            <div className="flex-1 text-center">
              <Button onClick={() => {handleAgentModal('add'); setAskModal(false)}} 
                label="Add Manually" 
                icon="pi pi-plus" 
                className=" bg-[#397BFF] rounded-lg border-[#397BFF] ring-0" 
                iconPos="right"
              />
            </div>
            <div className="flex-1 text-center">
              <Button onClick={() => navigate('/onboarding')} 
                label="Use Wizard" 
                icon="pi pi-sparkles" 
                className=" bg-[#397BFF] rounded-lg border-[#397BFF] ring-0" 
                iconPos="right"
              />
            </div>
          </div>
        </div>
      </Dialog>
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Campaigns</h1>
        <div>
        
          {/*
            <Button loading={importCsvLoading} onClick={importCsv} label="Import CSV" icon="pi pi-upload" className="add-campaign bg-slate-400 rounded-lg border-slate-400 ring-0 mr-2" />
          */}
          
          
          <Button loading={addCampaignLoading} onClick={handleAddCampaign} label="Add Campaign" icon="pi pi-plus" className="add-campaign bg-purple rounded-lg border-purple ring-0" />      
        </div>
      </div>

      <div className="panel bg-white rounded-lg p-5">
        <LazyTable
          api={api} apiAll={`/api/agents/select-all`} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable} setRefreshTable={setRefreshTable}
          hasOptions={true}
          checkbox={true}
          columns={agentsTableColumn}
          actions={true}
          action_types={{ edit: true, delete2: true, play: true }}
          actionsClicked={actionsClicked}
          expandableRow={true}
          rowExpansionTemplate={(rowData: any) => <ExpandedAgents key={rowData.agent_name} rowData={rowData} statusFunction={statusTemplate} listFunction={listTemplate} />}
          bulkOptions={[
            {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
          ]}
          filters={leadsFilter}
          filterValues={filterValues}
        />
      </div>
      {/* <AgentModal data={agentData} setData={handleAgentData} type={agentModalType} visible={agentModal} onSubmit={handleAgentSubmit} onHide={() => {handleAgentModalClose()}}/>
       */}
        <OnboardingSummaryModal 
          data={agentData} 
          isEdit={isEdit} 
          values={values} 
          values1={values1} 
          miscLinks={miscLinks}
          setMiscLinks={setMiscLinks}
          setValues1={setValues1} 
          values2={values2} 
          isGenerated={isGenerated} 
          isLoadingProfile={isLoadingProfile} 
          isLoadingPitch={isLoadingPitch} 
          isLoading={isLoading} 
          isSubmitting={isSubmitting} 
          generativeAI={handleGenerating} 
          setData={setAgentData} 
          header={modalTexts.header} 
          buttonText={modalTexts.button} 
          visible={agentModal} 
          onSubmit={handleAgentSubmit} 
          onHide={() => {handleAgentModalClose()}} 
          setLoadingState={setLoadingState}
          csvFile={csvFile} 
          setCsvFile={setCsvFile}
          key={agentModalKey}
        />
      <ConfirmDialog/>

      <AgentAddMoreLeadsModal axiosService={axiosService} key={agentAddMoreLeadsModalKey} isOpen={isAgentAddMoreLeadsModalOpen} onClose={() => setIsAgentAddMoreLeadsModalOpen(false)} agent_id={agentAddMoreLeadsModalAgentId} setLoadingState={setLoadingState} setRefreshTable={setRefreshTable} />
      
      <AgentImportCsvModal agent_data={agentData} isEdit={isEdit} axiosService={axiosService} key={agentImportCsvModalKey} isOpen={isAgentImportCsvModalOpen} onClose={() => {setIsAgentImportCsvModalOpen(false); setIsEdit(false);}} setLoadingState={setLoadingState} setRefreshTable={setRefreshTable} />


    </div>
  );
}

export default Agents;
