import axios from 'axios';

class AxiosService {
  api:any = null;
  root_domain_url:any = null;

  constructor() {
    const initApi = axios.create({
      baseURL: process.env.REACT_APP_API_URL, // Your backend server URL
    });
    const temp:any = process.env.REACT_APP_API_URL || '';
    this.root_domain_url = temp.replace(/\/app/g, '');
    this.api = initApi;
  }

  setHeaders(token: any) {
    if( typeof(sessionStorage.autologinr09nalqj4p)!='undefined' ){
      this.api.defaults.headers.common['x-autologinr09nalqj4p'] = sessionStorage.autologinr09nalqj4p;
    }
    this.api.defaults.headers.common['Authorization'] = token;
  }
}

export default AxiosService;
