import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Inputs from "../../components/forms/Inputs";
import { Logo, LoginIMG, LoginPlayIMG } from "../../components/Icons/ImgPath";
import { SignupProperties } from "../../interface/datatypes";
import { InputHandler } from "../../services/EventHandlers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAxios } from "../../contexts/AxiosContext";
import { Button } from "primereact/button";
import { useToast } from "../../contexts/ToastContext";
import { Dialog } from "primereact/dialog";
import OnboardingGuide from "../../components/modals/helps/OnboardingGuide";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA

const SignUpV2: React.FC<any> = ({ setLoadingState, signupDetails }) => {
  const axiosService = useAxios();
  const { showToast, clearToast } = useToast();
  const navigate = useNavigate();
  const [videoGuideModal, setVideoGuideModal] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null); // State for the captcha token

  const handleRegistration = (e: any) => {
    e.preventDefault();
    const form = e.target;
    const isValid = Array.from(form.elements).every((input: any) => input.checkValidity());

    if (signupDetails.variable.password !== signupDetails.variable.password_confirmation) {
      showToast({
        severity: 'error',
        summary: 'Invalid Registration!',
        detail: "Passwords do not match."
      });
      return null;
    }

    if (!captchaToken) {
      showToast({
        severity: 'error',
        summary: 'reCAPTCHA Error!',
        detail: "Please verify that you are not a robot."
      });
      return null;
    }

    setLoadingState(true);
    sessionStorage.clear();
    Cookies.remove('bearer_token');

    // Validate email before navigating
    if (isValid) {
      signupDetails.setter((prevValues: any) => ({
        ...prevValues,
        captcha_token: captchaToken, // Use the captcha token
      }));

      setLoadingState(true);
      showToast({
        severity: "info",
        summary: 'Please wait!',
        detail: "Validating captcha!",
        loading: true
      });

      axiosService.api.post(`/api/validate/captcha`, { captcha_token: captchaToken }).then((response: any) => {
        clearToast();

        showToast({
          severity: response.data.status ? "success" : "error",
          summary: response.data.status ? "Success!" : 'Invalid Registration!',
          detail: response.data.status ? "Captcha verified!": "Try again!",
        });
        
        if(response.data.status){
          navigate('/subscription-registration');
        }
        setLoadingState(false);
      }).catch((error: any) => {
        showToast({
          severity: 'error',
          summary: 'Invalid Captcha!',
          detail: "Try again!"
        });
        setLoadingState(false);

      });
    }
  };

  const handlePhoneChange = (newValue: any) => {
    signupDetails.setter((prevValues: any) => ({
      ...prevValues,
      phone: newValue
    }));
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 300);
  }, []);

  return (
    <div className="min-h-screen bg-white flex">
      {/* Left Side */}
      <div className="hidden md:flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-[#F0EFFF]">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-col items-center">
            <img className="h-12 w-auto mb-5" src={Logo} alt="SectorSift Logo" />
            <button
              className="mx-auto mb-4 ml-[-12rem] mt-[-1rem] h-auto w-[700px] max-w-[700px] p-0 border-none bg-transparent hover:cursor-pointer"
              onClick={() => setVideoGuideModal(true)}
            >
              <img
                className="w-full h-auto"
                src={LoginPlayIMG}
                alt="Login Logo"
              />
            </button>
            <div className="mt-[-5rem] flex flex-col justify-center">
              <h2 className="text-3xl font-medium text-gray-900 text-center">
                Try our new B2B Lead Generation Platform now.
              </h2>
              <p className="mt-2 text-[20px] font-light text-center text-gray-600">
                AI-powered platform streamlining B2B lead generation through precision targeting and market analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Side */}
      <div className="w-full flex justify-center items-center p-[3rem]">
        <div className="w-full max-w-lg">
          <div className="w-full">
            <div className="mb-4 flex flex-col items-center">
              <img className="block md:hidden h-12 w-auto mb-4" src={Logo} alt="SectorSift Logo" />
              <h3 className="mb-5 text-[28px] leading-6 font-medium text-gray-900 leading-[40px] text-center">
                Sign up and grow your business with <span className='font-poppins'>SectorSift</span>
              </h3>
            </div>
            <div className="mt-6">
              <form className="mb-5" onSubmit={handleRegistration}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <Inputs label={'First Name'} type={'text'} value={signupDetails.variable.firstname} onChange={InputHandler(signupDetails.setter, 'firstname')} required invalid withValidation />
                  <Inputs label={'Last Name'} type={'text'} value={signupDetails.variable.lastname} onChange={InputHandler(signupDetails.setter, 'lastname')} required invalid withValidation />
                  <Inputs label={'Email'} type={'email'} value={signupDetails.variable.email} onChange={InputHandler(signupDetails.setter, 'email')} required invalid withValidation />
                  <Inputs label={'Phone Number'} type={'phone'} value={signupDetails.variable.phone} onChange={(e: any) => handlePhoneChange(e)} required invalid withValidation />
                </div>
                <div className="mb-4">
                  <ReCAPTCHA
                    sitekey="6LdNrHUqAAAAADSd3wk0gO3RuvCznRwrttvthDnC" // Replace with your actual site key
                    onChange={(value) => setCaptchaToken(value)} // Store the token in state
                  />
                </div>
                <div className="">
                  <Button label="Sign me up" type="submit" className="w-full bg-[#397BFF] rounded-lg border-[#397BFF]" pt={{ label: { className: 'font-[600]' } }} />
                  <p className="text-center text-[12px] leading-7 font-normal mb-4">
                    By clicking Sign me up you agree to SectorSift's <a href="/terms-and-conditions" target="_blank" className="underline hover:cursor-pointer">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" className="underline hover:cursor-pointer">Privacy Policy</a>.
                  </p>

                  <p className="text-center text-[16px] leading-7 font-normal mb-5">
                    Already have an account? <Link to="/" className="underline cursor-pointer">Login!</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Dialog className="lg:w-50% w-[95%] text-center" header="" closable={true} draggable={false} resizable={false} visible={videoGuideModal} onHide={() => setVideoGuideModal(false)}>
        <section className="p-4 border-b-[1px] border-gray shadow-sm w-full bg-white z-[400]">
          <h1 className="text-3xl font-bold mb-4">Video Guide</h1>
          <p className="text-sm text-gray-500 mb-6">
            <strong>Video guide to help you get started</strong>
          </p>
        </section>
        <OnboardingGuide />
      </Dialog>
    </div>
  );
}

export default SignUpV2;