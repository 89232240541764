import React, { useEffect, useState } from "react";
import { Logo } from "../../components/Icons/ImgPath";

const TermsConditions: React.FC<any> = ({setLoadingState}) => {
  
  const [htmlContent, setHtmlContent] = useState<string>("");
 
  useEffect(() => {
    fetch("/docs/terms_and_conditions.html")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
        
      })
      .then((data) => {
        setLoadingState(false);
        setHtmlContent(data)
      })
      .catch((error) => console.error("Error fetching HTML content:", error));
  }, []);

  return (
    <>
      <section dangerouslySetInnerHTML={{ __html: htmlContent }}  />
      {/* <div className="">
        <section className="fixed p-4 border-b-[1px] border-gray shadow-sm w-full bg-white z-[400]">
          <img alt="Logo" src={Logo} className="mb-5" width={150}/>
          <h1 className="text-3xl font-bold mb-4">SectorSift Terms of Service</h1>
          <p className="text-sm text-gray-500 mb-6">
            <strong>Last Updated: August 13, 2024</strong>
          </p>
        </section>

        <section
          className="p-6 bg-white text-gray-800 pt-[13rem]"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />

        <section className="my-[2rem] flex justify-center">
          <p className="text-[13px]">If you have any questions or concerns about these Terms of Service, please contact us at: <a className="text-[#8D2CFE] hover:underline" href="mailto:support@sectorsift.com"> support@sectorsift.com</a></p>
        </section>
      </div> */}
    </>
  );
};

export default TermsConditions;
