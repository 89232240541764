import React,{useEffect, useState} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignUp from "../pages/auth/SignUp";
import LogIn from "../pages/auth/LogIn";
import Main from "../pages/main";
import Onboarding from "../pages/onboarding/Onboarding";
import Subscription from "../pages/Subscription";
import AxiosService from "../services/AxiosService";
// import Cookies from "js-cookie";
import VerifyAccount from "../pages/auth/VerifyAccount";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PageLoader from "../components/loaders/PageLoader";
import Callback from "../pages/callback/Callback";
import { AxiosProvider } from "../contexts/AxiosContext";
import ForgotPassword from "../pages/auth/ForgotPassword";
import PasswordReset from "../pages/auth/PasswordReset";
import ResetPassword from "../pages/auth/ResetPassword";
import SuperAdminRegistration from "../pages/superadmin/SuperAdminRegistration";
import SuperAdminLogin from "../pages/superadmin/SuperAdminLogin";
import SuperAdminMain from "../pages/superadmin/SuperAdminMain";
import ViC95burK4ziV0H4zG1mavuHm from "../pages/superadmin/ViC95burK4ziV0H4zG1mavuHm";
import InvitationAcceptance from "../pages/auth/InvitationAcceptance";
import AutoLogin from "../pages/auth/AutoLogin";
// import PilotLanding from "../pages/landings/PilotLanding";
import SubscriptionCallback from "../pages/callback/SubscriptionCallback";
import { SubscriptionRestrictionsProvider } from "../contexts/SubscriptionRestrictions";
import TermsConditions from "../pages/legal/TermsConditions";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import SignUpV2 from "../pages/auth/SignUpV2";
import SubscriptionV2 from "../pages/SubscriptionV2";
import SubscriptionCallbackV2 from "../pages/callback/SubscriptionCallbackV2";

const AppRouter: React.FC = () => {
  const [axiosService, setAxiosService] = useState(new AxiosService());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [signupDetails, setSignupDetails] = useState<any>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
  }, [signupDetails]);
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || '89563815322-00baeko973sb047m5onto4ch9b4gk4su.apps.googleusercontent.com'}>
      {isLoading && <PageLoader />}
      <Router>
        <AxiosProvider axiosService={axiosService}>
          <SubscriptionRestrictionsProvider>
            <Routes>
              <Route path="/" element={<LogIn setLoadingState={setIsLoading} />} />
              <Route path="/terms-and-conditions" element={<TermsConditions setLoadingState={setIsLoading} />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy setLoadingState={setIsLoading} />} />
              {/* <Route path="/signup" element={<SignUp setLoadingState={setIsLoading}  />} /> */}
              <Route path="/signup" element={<SignUpV2 setLoadingState={setIsLoading} signupDetails={{variable: signupDetails, setter: setSignupDetails}} />} />
              <Route path="/main" element={<Main setLoadingState={setIsLoading} setAxiosService={setAxiosService} axiosService={axiosService}/>} />
              <Route path="/onboarding" element={<Onboarding setLoadingState={setIsLoading} />} />
              <Route path="/subscription" element={<Subscription setLoadingState={setIsLoading} />} />
              <Route path="/subscription-registration" element={<SubscriptionV2 setLoadingState={setIsLoading} signupDetails={signupDetails} />} />
              <Route path="/verify-account" element={<VerifyAccount setLoadingState={setIsLoading}  />} />
              <Route path="/reset-password" element={<ResetPassword setLoadingState={setIsLoading}  />} />
              <Route path="/forgot-password" element={<ForgotPassword setLoadingState={setIsLoading}  />} />
              <Route path="/password-reset" element={<PasswordReset setLoadingState={setIsLoading}  />} />
              <Route path="/callback" element={<Callback setLoadingState={setIsLoading} />}/>
              <Route path="/subscription-callback" element={<SubscriptionCallback setLoadingState={setIsLoading} />}/>
              <Route path="/subscription-callback-V2" element={<SubscriptionCallbackV2 setLoadingState={setIsLoading} />}/>
              <Route path="/admin-KNdApfxpXK-registration" element={<SuperAdminRegistration setLoadingState={setIsLoading} />}/>
              <Route path="/admin-KNdApfxpXK-login" element={<SuperAdminLogin setLoadingState={setIsLoading} />}/>
              <Route path="/admin-KNdApfxpXK-main" element={<SuperAdminMain setLoadingState={setIsLoading} />}/>
              <Route path="ViC95burK4ziV0H4zG1mavuHm" element={<ViC95burK4ziV0H4zG1mavuHm setLoadingState={setIsLoading} />} />
              <Route path="/invitation-acceptance/:serial?" element={<InvitationAcceptance setLoadingState={setIsLoading}  />} />
              <Route path="/autologinlgzrrmgoz3/:id?" element={<AutoLogin setLoadingState={setIsLoading}  />} />
            </Routes>
          </SubscriptionRestrictionsProvider>
        </AxiosProvider>
      </Router>
    </GoogleOAuthProvider>
  )
};

export default AppRouter;
