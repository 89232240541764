import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import React, { useEffect, useState } from "react"
import { useAxios } from "../../../contexts/AxiosContext"
import { Dropdown } from "primereact/dropdown"
import { InputHandler } from "../../../services/EventHandlers"
import { Calendar } from "primereact/calendar"

interface Props {
  visible: boolean
  onHide?: any
  data?: any
  onSubmit?: any
  callback?: any
}

const LeadScheduleEmail: React.FC<Props> = ({ 
  visible = false, onHide, data, callback = null, onSubmit
}) => { 

  const axiosService = useAxios();
  
  // const footerContent = () => {
  //   return <div >
  //     <Button label="Confirm" onClick={onSubmit} className="bg-purple rounded-lg mr-0" autoFocus />
  //   </div>
  // }

  const [isLoading, setIsLoading] = useState(false);
  const [mailboxes, setMailboxes] = useState<any>([]);

  const mail = {
    mailbox_id: null,
    type: null,
    schedule_date: null,
    schedule_time: null
  }
  const [scheduleMailData, setScheduleMailData] = useState<any>(mail);

  const getMailboxes = () => {
    const newMailbox = { id: '2147483647', email: 'SectorSift (Default)' };
    setMailboxes([newMailbox]);
    axiosService.api.get('/api/mailboxes/all')
    .then((response:any) => {
      const modifiedData = response.data.map((item: any) => ({
        ...item,
        email: `${item.email} (${item.type})`
      }));
      
      setMailboxes((prevMailboxes: any) => [...modifiedData, ...prevMailboxes]);
      })
    .catch((error:any) => {
      console.log('Error fetching data:', error);
    });
  }

  const handleDropdownOnChange = (e: any) => {
    setScheduleMailData({
      ...scheduleMailData,
      mailbox_id: e.value.id
    });
  }

  const handleOnSubmit = (e:any) => {
    e.preventDefault();
    setIsLoading(true);
    onSubmit(scheduleMailData);
    onHide();
    setScheduleMailData(mail);
  }

  useEffect(() => {
    getMailboxes();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [visible])

  return(
    <Dialog className="lg:w-1/4 w-[95%]" header="Schedule Mail" draggable={false} resizable={false} visible={visible} onHide={onHide}>
      {/* <ComposeMail mailboxes={mailboxes} data={data} callback={callback}/>  */}
      <form onSubmit={handleOnSubmit}>
        <div className="grid gap-4">
          <div className="flex flex-col">
            <label className='flex items-center text-gray-700 text-sm font-bold mb-2'>Mailbox</label>
            <Dropdown value={mailboxes.filter((e:any) => e.id === scheduleMailData.mailbox_id )[0]} onChange={handleDropdownOnChange} options={mailboxes} optionLabel="email" 
              placeholder="Select Mailbox" className="w-full md:w-14rem" required/> 
          </div>
          <div className="flex flex-col">
            <label className='flex items-center text-gray-700 text-sm font-bold mb-2'>Template</label>
            <Dropdown value={scheduleMailData.type} onChange={(e:any) => InputHandler(setScheduleMailData, 'type')(e)} 
              options={[
                {value: 'first_email_template', name: 'First Email Template'},
                {value: 'follow_up_email_template', name: 'Follow-up Email Template'},
              ]} optionLabel="name" 
              placeholder="Select Template" className="w-full md:w-14rem" required/> 
          </div>

          <div className="flex flex-col">
            <label className='flex items-center text-gray-700 text-sm font-bold mb-2'>Date and Time</label>
            <Calendar value={scheduleMailData.schedule_date} placeholder="mm/dd/yyyy 00:00 AM" onChange={(e:any) => InputHandler(setScheduleMailData, 'schedule_date')(e)} showIcon showTime hourFormat="12" required/>
          </div>
{/* 
          <div className="flex flex-col">
            <label className='flex items-center text-gray-700 text-sm font-bold mb-2'>Time</label>
            <Calendar value={scheduleMailData.schedule_time} placeholder="HH:mm" onChange={(e:any) => InputHandler(setScheduleMailData, 'schedule_time')(e)} showIcon timeOnly icon={() => <i className="pi pi-clock" />} required/>
          </div> */}
          
        </div>

        <div className="flex justify-end mt-4">
          <Button loading={isLoading} label="Confirm" className="bg-purple rounded-lg mr-0" autoFocus />
        </div>
      </form>
      
    </Dialog>
  );
}


export default LeadScheduleEmail