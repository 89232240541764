import React, { useState, useRef, useEffect } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import PhoneInput from 'react-phone-number-input'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import _ from 'lodash';

interface AgentImportCsvModalProps {
  isOpen: boolean;
  onClose: () => void;
  setLoadingState: any;
  axiosService: any;
  setRefreshTable: any;
  agent_data: any;
  isEdit: boolean;
}

const AgentImportCsvModal: React.FC<AgentImportCsvModalProps> = ({ axiosService, isOpen, onClose, setLoadingState, setRefreshTable, agent_data, isEdit }) => {
  
  const [isOpen2, setIsOpen2] = useState(true);
  const [errorMsg, setErrorMsg] = useState<any>(null);
  
  const [credits, set__credits] = useState(10);
  const [full_name, set__full_name] = useState('');
  const [company_name, set__company_name] = useState('');
  const [email, set__email] = useState('');
  const [phone, set__phone] = useState<any>('');
  const [description, set__description] = useState('');
  const [target_language, set__target_language] = useState('English');
  
  const [err_credits, set__err_credits] = useState<any>(null);
  const [err_full_name, set__err_full_name] = useState<any>(null);
  const [err_company_name, set__err_company_name] = useState<any>(null);
  const [err_email, set__err_email] = useState<any>(null);
  const [err_description, set__err_description] = useState<any>(null);
  const [err_phone, set__err_phone] = useState<any>(null);
  
  const [languages, setLanguages] = useState([
    {name: "English", value: "English"},
    {name: "Spanish", value: "Spanish"},
    {name: "French", value: "French"},
    {name: "German", value: "German"},
    {name: "Chinese (Simplified)", value: "Chinese (Simplified)"},
    {name: "Chinese (Traditional)", value: "Chinese (Traditional)"},
    {name: "Japanese", value: "Japanese"},
    {name: "Korean", value: "Korean"},
    {name: "Russian", value: "Russian"},
    {name: "Portuguese", value: "Portuguese"},
    {name: "Italian", value: "Italian"},
    {name: "Dutch", value: "Dutch"},
    {name: "Arabic", value: "Arabic"},
    {name: "Hindi", value: "Hindi"},
    {name: "Bengali", value: "Bengali"},
    {name: "Punjabi", value: "Punjabi"},
    {name: "Turkish", value: "Turkish"},
    {name: "Vietnamese", value: "Vietnamese"},
    {name: "Thai", value: "Thai"},
  ]);  
  

  const [prefillList, setPrefillList] = useState([]);  
  const [prefillOptions, setPrefillOptions] = useState([]);  
  const [prefillChosen, setPrefillChosen] = useState('');    
  const [userData, setUserData] = useState(null);
  
  const fileUploadUi: any = useRef(null);


  useEffect( () => {
    if( isEdit ){
        
      if( agent_data.status == 'Pending' || agent_data.status == 'In-Progress' ){
        withReactContent(Swal).fire({
          icon: "info",
          text: 'Leads are Being Retrieved from CSV',
          iconColor: '#8fcaea',
          confirmButtonColor: '#2196f3',
        });      
        setIsOpen2(false);
        return;
      }
    
      set__full_name( agent_data.full_name );
      set__company_name( agent_data.company_name );
      set__email( agent_data.email );
      set__phone( agent_data.phone );
      set__description( agent_data.description );
      set__target_language( agent_data.target_language );    
    }    
  
  
    axiosService.api.get('/api/agents/import-csv/get-prefill-list')
      .then( (data: any) =>{
          setPrefillList(data.data.agents);
          const prefillOptionsTemp = data.data.agents.map( (o: any)=>({ 'value': o.id, 'name': o.name }) );
          setPrefillOptions( prefillOptionsTemp );
      });
      
      axiosService.api.get('/api/user/current')   
      .then( (data: any) =>{
        setUserData(data.data);
      });      
      
      

      
  }, []);  
  

  const submitHandler = async() => {
    const current: any = fileUploadUi.current;
    const files: any = current.getFiles();

    let is_valid = true;

    
    if( files.length === 0 && !isEdit ){
      setErrorMsg( <div className="text-xs text-red-800 text-center mt-1 italic">Please choose a CSV file.</div> );
      is_valid = false;
    }
    
    const phone_length = _.get(phone, 'length', 0);
    if(phone_length===0){
      set__err_phone( null );
    }else if(phone_length<=5){
      set__err_phone( <span className="text-xs font-normal text-red-700 mt-1 italic">(Invalid Phone)</span> );
      is_valid = false;
    }else if(phone_length>=31){
      set__err_phone( <span className="text-xs font-normal text-red-700 mt-1 italic">(Invalid Phone)</span> );
      is_valid = false;      
    }else{
      set__err_phone( null );
    }
    
    const available_credit = _.get(userData, 'available_credit', 0) || 0;
    if( credits===0 ){
      set__err_credits( <span className="text-xs font-normal text-red-700 mt-1 italic">(Required)</span> );
      is_valid = false;
    }else if( credits<0 ){
      set__err_credits( <span className="text-xs font-normal text-red-700 mt-1 italic">(Needs a Positive Number)</span> );
      is_valid = false;
    }else if( available_credit < credits ){
      set__err_credits( <span className="text-xs font-normal text-red-700 mt-1 italic">(Not Enough Credits)</span> );
      is_valid = false;
    }else{
      set__err_credits( null );
    }

    if( full_name.trim() === '' ){
      set__err_full_name( <span className="text-xs font-normal text-red-700 mt-1 italic">(Required)</span> );
      is_valid = false;
    }else{
      set__err_full_name( null );
    }  
    
    if( company_name.trim() === '' ){
      set__err_company_name( <span className="text-xs font-normal text-red-700 mt-1 italic">(Required)</span> );
      is_valid = false;
    }else{
      set__err_company_name( null );
    }
    
    if( email.trim() === '' ){
      set__err_email( <span className="text-xs font-normal text-red-700 mt-1 italic">(Required)</span> );
      is_valid = false;
    }else if(!( email.toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ) )){
      set__err_email( <span className="text-xs font-normal text-red-700 mt-1 italic">(Invalid Email)</span> );
      is_valid = false;
    }else{
      set__err_email( null );
    }
    
    if( description.trim() === '' ){
      set__err_description( <span className="text-xs font-normal text-red-700 mt-1 italic">(Required)</span> );
      is_valid = false;
    }else{
      set__err_description( null );
    }        
        
    
    if( !is_valid ){ return; }
    
    
    setLoadingState(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    
    formData.append("company_name", company_name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("description", description);
    formData.append("target_language", target_language);
    formData.append("credits", credits.toString());
    formData.append("full_name", full_name);
    
    
    
    if(isEdit){
      
      formData.append("agent_id", agent_data.id);
      
      const res_cprzm2zu: any = await axiosService.api.post('api/agents/import-csv/update', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoadingState(false);
      
      if( !res_cprzm2zu.data.status ){
        setErrorMsg( <div className="text-xs text-red-800 text-center mt-1 italic">{res_cprzm2zu.data.msg}</div> );
        return false;    
      }    
    }else{
      const res_itledee0ya: any = await axiosService.api.post('api/agents/import-csv', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoadingState(false);
      
      if( !res_itledee0ya.data.status ){
        setErrorMsg( <div className="text-xs text-red-800 text-center mt-1 italic">{res_itledee0ya.data.msg}</div> );
        return false;    
      }    
    }
        

    
    setIsOpen2( false );
    setRefreshTable(true);
    
    await withReactContent(Swal).fire({
      icon: "success",
      text: 'Your CSV and other details have been sent.',
      iconColor: '#8fcaea',
      confirmButtonColor: '#2196f3',
    });    
    
    
  }
  
  const resetHandler = () => {
    fileUploadUi.current.clear();
    setErrorMsg( null );
  }
  
  const selectHandler = () => {
    setErrorMsg( null );
  }
  
  const downloadSampleFile = () =>{
    window.open('/sample-files/sample.csv', '_blank');
  };
  

  const prefillHandler = async() => {
  
    if( prefillChosen==='' ){ return; }
    
    const temp_gwybijk = prefillList.filter( (o: any)=>( o.id === prefillChosen ) );
    
    if( temp_gwybijk.length===0 ){ return; }
    const item: any = temp_gwybijk[0];


    set__full_name( item.full_name );
    set__company_name( item.company_name );
    set__email( item.email );
    set__phone( item.phone );
    set__description( item.description );
    set__target_language( item.target_language );
    
  
  };  
  
  
  const temp_el: any = document.querySelector('.h-screen');
  temp_el.style.height='100vh';
  
  if (!isOpen){ return null; }
  if (!isOpen2){ return null; }
  
  if( window.innerHeight<=900 ){
    temp_el.style.height='900px';
  }
  
  const heading_text = isEdit ? 'Edit Import CSV' : 'Import CSV';
  
  const submit_text = isEdit ? 'Rerun' : 'Import';
  
  return (
    <div>
    
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center text-gray-800"></div>  
      
      <div className="absolute  flex  text-gray-700 top-[100px] left-[calc(50%-200px)] pb-5">  
        <div className="flex  text-gray-700">
          <div className="bg-white rounded-lg p-6 w-96">
          
            <h2 className="text-3xl font-bold mb-1 text-center">{heading_text}</h2>
            
            <div className="mb-2 text-xs text-gray-600"><span>To import a CSV file, please use the following format from the sample file.</span> <span className="text-blue-300 italic cursor-pointer hover:text-blue-400" onClick={downloadSampleFile}>[Download Sample File]</span></div>
            
            <div className="mb-2">
              <div className="flex justify-center">
                  <FileUpload ref={fileUploadUi} mode="basic" customUpload={true} uploadHandler={resetHandler} onSelect={selectHandler} chooseLabel="Choose CSV File" name="file[]" url="" accept="text/csv" maxFileSize={104857600}  />

              </div>
              {errorMsg}
            </div>
            
            <div className="mb-2">
              <div className="text-xs text-gray-600">Please provide the following details to enable us to generate personalized email messages for you.</div>
            </div>
      
            <div className="mb-2">        
            
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Prefill, Copy From:  <span className="font-normal text-gray-500">(optional)</span></div>
                <div className="relative">

                  <Dropdown value={prefillChosen} onChange={(e) => setPrefillChosen(e.value)} options={prefillOptions} optionLabel="name" 
        placeholder="Select a Campaign" className="md:w-14rem pt-0 pb-0 p-inputtext-sm w-[calc(100%-83px)]" />

                  <button
                    onClick={prefillHandler}
                    className="p-inputtext-sm bg-blue-500 hover:bg-blue-600 text-white px-4 py-[6px] rounded mr-2 absolute right-[-5px]"
                    >
                    Prefill
                  </button>  
                </div>
              </div>
              
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">What language should we use?</div>
                <div><Dropdown value={target_language} onChange={(e) => set__target_language(e.value)} options={languages} optionLabel="name" 
        placeholder="Select a Language" className="w-full md:w-14rem pt-0 pb-0 p-inputtext-sm" /></div>
              </div>          
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Company Name: {err_company_name}</div>
                <div><InputText className="p-inputtext-sm w-full pt-1 pb-1" value={company_name} onChange={(e) => set__company_name(e.target.value)} /></div>
              </div>
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Full Name: {err_full_name}</div>
                <div><InputText className="p-inputtext-sm w-full pt-1 pb-1" value={full_name} onChange={(e) => set__full_name(e.target.value)} /></div>
              </div>
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Email: {err_email}</div>
                <div><InputText className="p-inputtext-sm w-full pt-1 pb-1" value={email} onChange={(e) => set__email(e.target.value)} /></div>
              </div>
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Phone: <span className="font-normal text-gray-500">(optional)</span> {err_phone}</div>
                <div>
                  <PhoneInput
                    international
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={set__phone} 
                    rules={{ required: true }} 
                    className="text-sm w-full pt-1 pb-1"
                    />
                </div>
              </div>
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Description of My Business: {err_description}</div>
                <div><InputTextarea value={description} onChange={(e) => set__description(e.target.value)} rows={3} className="p-inputtext-sm w-full pt-1 pb-1 resize-none" /></div>
              </div>
              
              <div className="mb-1">
                <div className="text-[14px] font-[500] mb-[-1px] text-gray-700">Credits to Use: {err_credits}</div>
                <div>
                  <InputNumber
                      value={credits}
                      onChange={(e:any) => set__credits(Number(e.value))}
                      className="p-inputtext-sm w-full rounded py-1 hide-spinner swal2-input text-center hide-spinner"
                  />
                </div>
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded mr-2 text-gray-500"
                >
                Cancel
              </button>   
              
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2"
                onClick={submitHandler}
                >
                {submit_text} 
              </button>
              
            </div>
            
          </div>
        </div>
      </div>

    </div>
  );
};

export default AgentImportCsvModal;
