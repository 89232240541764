import { useEffect, useState,useRef } from "react";
import Table from "../../components/tables/Table";
import { BlockUI } from 'primereact/blockui';
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import IconMap from "../../components/Icons/IconMaps";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import SendMailModal from "../../components/modals/mailbox/SendMailModal";
import { useToast } from "../../contexts/ToastContext";
import { Dropdown } from "primereact/dropdown";
import { useAxios } from "../../contexts/AxiosContext";
import LeadMailSystemModal from "../../components/modals/leads/LeadMailSystemModal";
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputHandler } from "../../services/EventHandlers";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

interface ExpandedLeadsProps {
  data: any; 
  emailStatusFunction: any;
  callback?: any;
  onDealStatusCallback?: any;
  setRefreshTable?: any;
  setRefreshAccount?: any;
}

const ExpandedLeads: React.FC<ExpandedLeadsProps> = ({ setRefreshAccount, data, setRefreshTable, emailStatusFunction, callback = null, onDealStatusCallback = null }) => {
  const axiosService = useAxios();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const {showToast,clearToast} = useToast();
  const [mailModalData, setMailModalData] = useState<any>();
  const [mailModalShow, setMailModalShow] = useState(false); 
  const [isUnlocking, setIsUnlocking] = useState<number | null>(null);
  const [rowData, setRowData] = useState(data);
  const [availableContactPersonCount, setAvailableContactPersonCount] = useState<any>({'not_yet_pulled':0, 'cost_credits':0});
  const [gettingMoreNamesLoading, setGettingMoreNamesLoading] = useState(false);

  const [saveTemplateFirstLoading, setSaveTemplateFirstLoading] = useState(false);
  const [saveTemplateFollowupLoading, setSaveTemplateFollowupLoading] = useState(false);
  const handleSaveTemplate = (type: string) => {
    const lead_id = rowData && rowData.id;
    const data = {
      type: type,
      subject: type === "first" ? rowData.first_email_subject : rowData.follow_up_email_subject,
      template: type === "first" ? rowData.first_email_template : rowData.follow_up_email_template,
    }

    setSaveTemplateFirstLoading(type === "first");
    setSaveTemplateFollowupLoading(type === "followup")

    showToast({
      severity: 'info',
      summary: 'Processing!',
      detail: 'Updating the template.'
    });
    axiosService.api.post(`/api/leads/template_update/${lead_id}`, data)
    .then((response:any) => {
      if(response.data.status){
        setRefreshTable(true);
      }
      showToast({
        severity: response.data.status ? 'success' : 'error',
        summary: response.data.status? 'Success!' : 'Error!',
        detail: response.data.message
      });

      setSaveTemplateFirstLoading(false);
      setSaveTemplateFollowupLoading(false);
    }).catch((error:any) => {
      console.log(error);
    });
  }

  const [dealStatus, setDealStatus] = useState((rowData && rowData.deal_status) || 'New'); 
  const handleSendMail = (type: string) => {
    let subject = "";
    let body = "";
    let to = (rowData.main_contact && rowData.main_contact.email) || '';
    let other_contacts = rowData.contacts.filter((contact: any) => contact.unlocked == 1 && contact.email != rowData.main_contact.email).map((contact: any) => contact.email);

    const updatedTo = [
      to, 
      ...other_contacts
    ];
    
    if(to === ''){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'This lead doesn\'t have a Main Contact email address.'
      });
    }
    
    if (type === "first") {
      subject = rowData.first_email_subject;
      body = rowData.first_email_template;
    }

    if (type === "followup") {
      subject = rowData.follow_up_email_subject;
      body = rowData.follow_up_email_template;
    }

    setMailModalData({
      subject: subject,
      body: body,
      to: updatedTo,
      lead_id: rowData.id,
      type: type
    });
    const sendmail = setTimeout(() => {
      setMailModalShow(true);
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const handleMailToClient = (type: string) => {
    let subject = "";
    let body = "";
    let to = (rowData.main_contact && rowData.main_contact.email) || '';
    
    if(to === ''){
      showToast({
        severity: 'warn',
        summary: 'Error!',
        detail: 'This lead doesn\'t have a Main Contact email address.'
      });
    }

    if (type === "first") {
      subject = rowData.first_email_subject;
      body = rowData.first_email_template;
    }

    if (type === "followup") {
      subject = rowData.follow_up_email_subject;
      body = rowData.follow_up_email_template;
    }

    const mailtoLink = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    const sendmail = setTimeout(() => {
      window.open(mailtoLink, '_blank');
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const mailToClient = (to: any, subject: string, body: string) => {
    const mailtoLink = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    const sendmail = setTimeout(() => {
      window.open(mailtoLink, '_blank');
      clearTimeout(sendmail);
    }, (to === '' ? 500 : 0));
  }

  const mailUseMailbox = (data: any) => {
    setMailModalData({
      subject: data.subject,
      body: data.body,
      to: data.to,
      lead_id: data.lead_id,
      type: data.type,
    });
    const sendmail = setTimeout(() => {
      setMailModalShow(true);
      clearTimeout(sendmail);
    }, (data.to === '' ? 500 : 0));
  }

  const handleCallback = () => {
    setMailModalShow(false);
    setMailSystemModal(false);
    if(callback) {
      callback();
    }
  }

  const blockTemplate = (rowID:any) => {
    return (
      <div className="flex items-center">
        <span className="text-[16px] text-black font-[600] flex items-center  hover:cursor-pointer"  onClick={() => (unlockContact(rowID))}>
          {/* test */}
          {isUnlocking === rowID? <span className="px-1 flex flex-row"><ProgressSpinner className="w-[25px] h-[25px] mr-1" strokeWidth="6" /> Unlocking...</span> : <i className="pi pi-lock text-red-600 text-[24px] font-[600] "></i>}
        </span>
      </div>
    );
  };

  const discoveredContactsTemplate = (keywords: string, rowData: any) => {
    return (
      rowData.unlocked === 0 ? (
        <BlockUI
          blocked={true}
          className="flex items-center important-bg-white backdrop-blur-sm z-[0] px-3"
          template={blockTemplate(rowData.id)}
        >
          <span className="flex justify-center">{keywords}</span>
        </BlockUI>
      ) : (
        <span>{keywords}</span>
      )
    );
  };
  
  const discoveredContactsColumn = [
    { field: 'name', header: 'Contact Name',  },
    { field: 'email', header: 'Email',template: discoveredContactsTemplate, hasTemplate: true },
    { field: 'role', header: 'Title/Position' },
    { field: 'phone', header: 'Phone',template: discoveredContactsTemplate, hasTemplate: true },
  ]

  const emailTemplates = [
    { field: 'template_name', header: 'Template Name' },
    { field: 'subject', header: 'Subject' },
  ]

  const copyToClipboard = (label:string, text: string) => {
    navigator.clipboard.writeText(text);
    toast.current?.show({ severity: 'info', detail: `Copied ${label}` });
  };

  const unlockContact = (contactId:number) => {
    // console.log('hit1')
    showToast({
      severity: 'warn',
      summary: 'Unlocking!',
      detail: 'Please wait. We are unlocking this contact for you.'
    });
    setIsUnlocking(contactId);
    axiosService.api.get(`/api/contact/unlock/${contactId}`)
    .then((response:any) => {
      if(response.data.status){
        axiosService.api.get('/api/leads/get/' + rowData.id)
        .then((response1:any)=>{
          if(response1.status){

            setRowData(response1.data);

            setRefreshAccount(true);
            setRefreshTable(true);
            setIsUnlocking(null);

            showToast({
              severity: response.data.status ? 'success' : 'error',
              summary: response.data.status? 'Success!' : 'Error!',
              detail: response.data.message
            });

          }
        })
      }
    }).catch((error:any) => {
      setRefreshTable(false);
      showToast({
        severity: 'error',
        summary: 'Insufficient Credits!',
        detail: error.response.data.message
      });
      setIsUnlocking(null);
    });
  };
  
  const [mailSystemModal, setMailSystemModal] = useState(false);
  const [mailSystemData, setMailSystemData] = useState();

  const customActions = (data: any) => {
    const newData = {
      ...data,
      contacts: rowData.contacts,
      lead_id: rowData.id,
      email_templates: rowData.email_templates
    }
    return (
      <>
        <div className="flex justify-center gap-5">
          {
            data && data.linkedin && (
              <a className="flex items-center p-[2px]" href={data.linkedin} target="_blank" rel="noopener noreferrer">
                <i className="pi pi-linkedin rounded text-purple"></i>
              </a>
            )
          }

          <a className="flex items-center p-[2px]" href={'tel:' + data.phone} target="_blank" rel="noopener noreferrer">
            <i className="pi pi-phone rounded text-purple"></i>
          </a>

          <Button 
            onClick={() => {setMailSystemModal(true); setMailSystemData(newData);}} 
            className="text-purple"
            icon="pi pi-envelope" 
            rounded 
            text 
            aria-label="Mail" 
          />
          
          <Button 
            onClick={() => (unlockContact(data.id))} 
            className={(data.unlocked == 1) ? "text-gray-500 ring-0 p-0" : "text-green-500 ring-0 p-0"} 
            icon="pi pi-unlock" 
            rounded 
            text 
            aria-label="Unlock" 
            disabled={(data.unlocked == 1)}
          />
          <Button className="text-red-500 ring-0 p-0" icon="pi pi-trash" rounded text aria-label="Trash" />
        </div>
      </>
    ); 
  }

  const emailTemplateCustomActions = (data: any) => {
    return (
      <>
        <div className="flex justify-start gap-2">
          <Button 
            onClick={() => { mailToClient(
              rowData.contacts.filter((contact: any) => contact.id == data.contact_id).map((contact: any) => contact.email),
              data.subject,
              data.template
            ) }} 
            className="text-purple border-purple rounded-lg hover:bg-purple hover:text-white"
            icon="pi pi-envelope" 
            text 
            aria-label="Mail"
            label="Use in Mail Client" 
          />

          <Button 
            onClick={() => {
              mailUseMailbox({
                to: rowData.contacts.filter((contact: any) => contact.id == data.contact_id).map((contact: any) => contact.email),
                subject: data.subject,
                body: data.template,
                lead_id: data.lead_id,
                type: data.template_type,
              });
            }} 
            className="text-white bg-purple rounded-lg"
            icon="pi pi-envelope" 
            text 
            aria-label="Mail"
            label="Use in Mailbox" 
          />
          
        </div>
      </>
    ); 
  }

  const handleBulkExportToHubspot = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);

    // console.log(' lead ::: ', data)
    showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to hubspot is in progress.`, sticky: true, loading: true, });
    axiosService.api.post('/api/leads/export-contacts/hubspot', { contacts: ids, leadId: data.id }).then((response:any)=>{
      // getData(activeAgent.id);
      setRefreshTable(true);
      clearToast();
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    }).catch((error:any)=>{
      clearToast();
      console.log('error ::: ', error);
      showToast({ severity: 'error', summary: 'Unable to export!', detail: error.response.data.message });
    })
  }

  const handleBulkExportToZoho = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);

    // console.log(' lead ::: ', data)
    showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to Zoho is in progress.`, sticky: true, loading: true, });
    axiosService.api.post('/api/leads/export-contacts/zoho', { contacts: ids, leadId: data.id }).then((response:any)=>{
      // getData(activeAgent.id);
      setRefreshTable(true);
      clearToast();
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    }).catch((error:any)=>{
      clearToast();
      showToast({ severity: 'error', summary: 'Unable to export!', detail: error.response.data.message });
    })
  }
  const handleBulkExportToSalesforce = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);

    // showToast({ severity: 'warn', summary: 'Still Developing', detail: `This feature is still in development.` });
    // console.log(' lead ::: ', data)
    showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to Salesforce is in progress.`, sticky: true, loading: true, });
    axiosService.api.post('/api/leads/export-contacts/salesforce', { contacts: ids, leadId: data.id }).then((response:any)=>{
      // getData(activeAgent.id);
      setRefreshTable(true);
      clearToast();
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    }).catch((error:any)=>{
      clearToast();
      showToast({ severity: 'error', summary: 'Unable to export!', detail:  error.response.data.message });
    })
  }

  const onDealStatusChange = (e: any, lead_id: any) => {
    setDealStatus(e.value);
    if(onDealStatusCallback){
      onDealStatusCallback(e.value, lead_id);
    }
  }
  
  const get_more_apollo_names = async( agent_id: any ) => {
    const v = await withReactContent(Swal).fire({
      title: "Are you sure to get more names?",
      confirmButtonText: 'Get More',
      cancelButtonText: 'Cancel',
      showCancelButton: true
    });
    
    if(v.isConfirmed){
      setGettingMoreNamesLoading(true);
      axiosService.api.post('/api/contact/pull_more_apollo_names', {id:agent_id})
      .then((response:any) => {
        if(response.data.status){
        
          axiosService.api.get('/api/leads/get/' + rowData.id)
          .then((response1:any)=>{
            if(response1.status){

              setRowData(response1.data);

              setRefreshAccount(true);
              setRefreshTable(true);
              setIsUnlocking(null);
              setGettingMoreNamesLoading(false);

            }
          })
          
        }
      }).catch((error:any) => {
        console.log(error);
      });    
    }
    
  }

  const tabPanels: any = [
    {
      title: 'Main Details',
      content: (
        <>
          <div className="flex gap-5">
            <div className="flex flex-col gap-5 lg:w-1/3">
              <div className="flex flex-col gap-1">
                <label className="text-label flex">Company Name<span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('company name',rowData.company_name)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                <p>{(rowData != null && rowData.company_name != null && rowData.company_name != undefined) ? rowData.company_name : '---'}</p>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-label flex">Website URL <span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('website URL',rowData.website)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                <p>{(rowData != null && rowData.website != null && rowData.website != undefined) ? <a target="_blank" rel="noopener noreferrer" href={ ( rowData.website.indexOf('https://')==-1 && rowData.website.indexOf('http://')==-1 )?(`https://${rowData.website}`):rowData.website }>{rowData.website}</a> : '---'}</p>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-label flex">Discovered On <span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('discovered on',rowData.discovered_on)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                <p>{(rowData != null && rowData.discovered_on != null && rowData.discovered_on != undefined) ? rowData.discovered_on : '---'}</p>
              </div>
              <div className="flex gap-5">
                <div className="flex flex-col justify-between gap-1">
                  <label className="text-label flex">First Email Status <span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('first email status',rowData.first_email_status)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                  <p>{(rowData != null && rowData.first_email_status != null && rowData.first_email_status != undefined) ? emailStatusFunction(rowData.first_email_status, rowData) : "---"}</p>
                </div>
                <div className="flex flex-col justify-between gap-1">
                  <label className="text-label flex">Follow-up Email Status <span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('follow-up email status',rowData.follow_up_status)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                  <p>{(rowData != null && rowData.follow_up_status != null && rowData.follow_up_status != undefined) ? emailStatusFunction(rowData.follow_up_status, rowData) : '---'}</p>
                </div>
                <div className="flex flex-col justify-between gap-1">
                  <label className="text-label flex">Deal Status</label>
                  <Dropdown value={dealStatus} 
                    optionLabel="value"
                    placeholder="Select Deal Status"
                    options={[
                      {value: 'New'}, 
                      {value: 'In Progress'}, 
                      {value: 'Won'}, 
                      {value: 'Lost'}
                    ]}
                    className="rounded-lg w-full bg-transparent" 
                    onChange={(e) => onDealStatusChange(e, rowData.id)}
                  />
                </div>
              </div>
            </div>
            <div className="lg:w-2/3">
              <div className="flex flex-col gap-1 border rounded-lg shadow-md p-5">
                <label className="text-label flex">Biography <span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('biography',rowData.biography)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                <p>{(rowData != null && rowData.biography != null && rowData.biography != undefined) ? rowData.biography : '---'}</p>
              </div>
            </div>
            
          </div>
        </>
      )
    },
    rowData.raw_lead && {
      title: 'AI Details',
      content: (
        <>
          <div className="flex flex-col gap-2">
            <div className="flex gap-5 justify-between">
              <div className="flex flex-row gap-1">
                <label className="text-label">Keywords</label>
                <span className="cursor-pointer" onClick={() => copyToClipboard('Copied AI Keywords',rowData.raw_lead.keywords.keywords)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span>
              </div>
            </div>

            <div className="flex flex-row gap-2 w-full">
              {/* {rowData.raw_lead?.keywords?.keywords && (
                rowData.raw_lead.keywords.keywords.split(',').map((keyword:any, index:any) => (
                  <Chip key={index} label={keyword.trim()} className="mr-1" />
                ))
              )} */}
              <InputTextarea className="w-full h-full rounded-lg border-none" rows={5} value={rowData.raw_lead?.keywords?.keywords} readOnly onChange={undefined}  />
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Lead Contact Details',
      content: (
        <>
          <div className="flex flex-col gap-5">
            <div className="flex gap-5 justify-between">
              <div className="flex flex-col gap-1">
                <label className="text-label">Lead Contact Details</label>
                <p className="flex">
                  {(rowData.main_contact != null && rowData.main_contact.name != null && rowData.main_contact.name != undefined) ? rowData.main_contact.name : '---'}
                  <span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('lead contact name',rowData.main_contact.name)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span>
                </p>
                {/* <p>Debbie Bates</p> */}
              </div>
              <div className="cursor-pointer select-none flex gap-2 items-center justify-center py-2 px-4 border rounded-lg border-gray 
                hover:bg-purple hover:text-white hover:border-purple transition
              ">
                <span>Edit Details</span>
                <i className="pi pi-pencil"></i>
              </div>
            </div>

            <div className="flex flex-col gap-5 w-1/3">
              <div className="flex flex-col gap-1">
                <label className="text-label flex">Role<span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('lead role',rowData.main_contact.role)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                <p>{(rowData.main_contact != null && rowData.main_contact.role != null && rowData.main_contact.role != undefined) ? rowData.main_contact.role : '---'}</p>
                {/* <p>Marketing Analyst</p> */}
              </div>
              <div className="flex gap-5">
                <div className="flex flex-col gap-1">
                  <label className="text-label flex">Email<span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('lead email',rowData.main_contact.email)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                  <p>{(rowData.main_contact != null && rowData.main_contact.email != null && rowData.main_contact.email != undefined) ? <a href={`mailto:${rowData.main_contact.email}`}>{rowData.main_contact.email}</a> : '---'}</p>
                  {/* <p>debbie@best.au</p> */}
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-label flex">Phone<span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('lead phone number',rowData.main_contact.phone)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                  <p>{(rowData.main_contact != null && rowData.main_contact.phone != null && rowData.main_contact.phone != undefined) ? <a href={`tel:${rowData.main_contact.phone}`}>{rowData.main_contact.phone}</a> : '---'}</p>
                  {/* <p>+07699955336655</p> */}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-label flex">Linkedin Profile<span className="ml-1 cursor-pointer" onClick={() => copyToClipboard('lead linkedin profile',rowData.main_contact.linkedin)}>{IconMap('FaRegCopy',undefined,undefined,14)}</span></label>
                <p>{(rowData.main_contact != null && rowData.main_contact.linkedin != null && rowData.main_contact.linkedin != undefined) ? <a href={rowData.main_contact.linkedin} target="_blank" rel="noopener noreferrer">{rowData.main_contact.linkedin}</a> : '---'}</p>
                {/* <p>https://linkedin.com/debiebates7845</p> */}
              </div>
              
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Contacts Discovered', 
      content: (
        <>
          <Table
            hasOptions={true}
            checkbox={true} columns={discoveredContactsColumn} 
            data={
              rowData.contacts && rowData.contacts.map((e:any) => {
                return {
                  id: e.id,
                  name: e.name,
                  role: e.role,
                  email: e.email,
                  phone: e.phone,
                  linkedin: e.linkedin,
                  unlocked: e.unlocked,
                }
              })
            }
            bulkOptions={
              (() => {
                const bulkOptions = [];
                if (rowData.hubspot_company) {
                  bulkOptions.push({name: 'Export to HubSpot', icon: IconMap('FaHubspot'), value: 'hubspot', onSubmit: handleBulkExportToHubspot});
                }
                if (rowData.zoho_company) { 
                  bulkOptions.push({ name: 'Export to Zoho', icon: IconMap('SiZoho'),value: 'zoho', onSubmit: handleBulkExportToZoho});
                }
                if (rowData.salesforce_company) {
                  bulkOptions.push({ name: 'Export to Salesforce', icon: IconMap('LiaSalesforce'), value: 'salesforce', onSubmit: handleBulkExportToSalesforce});
                }
                return bulkOptions.length > 0 ? bulkOptions : undefined;
              })()
            }
            actions={true} customActions={customActions}
            paginator={false} 
            searchOnly={true}
          />
          {
            (availableContactPersonCount.not_yet_pulled!=0) && <div className="flex justify-center">
              {
                !gettingMoreNamesLoading && <Button className="bg-purple rounded-lg border-none ring-0 p-button p-component mt-3" onClick={()=>{get_more_apollo_names(rowData.id)}}>Get {availableContactPersonCount.not_yet_pulled} More {availableContactPersonCount.not_yet_pulled>=2 ? 'Names':'Name' } for {availableContactPersonCount.cost_credits} { availableContactPersonCount.cost_credits>=2 ? 'Credits':'Credit' }</Button>
              }
              {
                gettingMoreNamesLoading && <Button className="bg-purple rounded-lg border-none ring-0 p-button p-component mt-3"><>Getting More Names <ProgressSpinner className="w-[25px] h-[25px] ms-1" strokeWidth="6" /></></Button>
              }
            </div>
            
            
          }
        </>
      )
    },
    {
      title: 'First Email Template',
      content: (
        <>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="first_email_subject">First Email Subject</label>
              <InputText value={rowData && rowData.first_email_subject} onChange={(e:any) => InputHandler(setRowData, 'first_email_subject')(e) } id="first_email_subject" name="first_email_subject" className="rounded-lg w-full" />
            </div> 
            
            <div className="flex flex-col gap-2">
              <label htmlFor="first_email_template">First Email Template</label>
              <InputTextarea value={rowData && rowData.first_email_template} onChange={(e:any) => InputHandler(setRowData, 'first_email_template')(e) } name="first_email_template" className="rounded-lg w-full" style={{ height: '200px' }} />
            </div> 

            <div className="flex gap-2 justify-end">
              <Button type="button" label="Use in Mail Client" icon="pi pi-envelope transition-all" className="border-purple bg-transparent text-purple hover:bg-purple hover:text-white transition-all rounded-lg" onClick={() => handleMailToClient('first')} />
              <Button type="button" label="Use in Mailbox" className="bg-purple text-white rounded-lg" icon="pi pi-envelope" onClick={() => handleSendMail('first')} />
              <Button disabled={(rowData.first_email_subject == data.first_email_subject && rowData.first_email_template == data.first_email_template)}
                type="button" label="Save Template" loading={saveTemplateFirstLoading} className="bg-green-500 text-white rounded-lg border-green-500" onClick={() => handleSaveTemplate('first')} />
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Follow-Up Email Template',
      content: (
        <>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="follow_up_email_subject">Follow-up Email Subject</label>
              <InputText value={rowData && rowData.follow_up_email_subject} onChange={(e:any) => InputHandler(setRowData, 'follow_up_email_subject')(e) } id="follow_up_email_subject" name="follow_up_email_subject" className="rounded-lg w-full" />
            </div> 
            
            <div className="flex flex-col gap-2">
              <label htmlFor="follow_up_email_template">Follow-up Email Template</label>
              <InputTextarea value={rowData && rowData.follow_up_email_template} onChange={(e:any) => InputHandler(setRowData, 'follow_up_email_template')(e) } name="follow_up_email_template" className="rounded-lg w-full" style={{ height: '200px' }} />
            </div> 

            <div className="flex gap-2 justify-end">
              <Button type="button" label="Use in Mail Client" icon="pi pi-envelope transition-all" className="border-purple bg-transparent text-purple hover:bg-purple hover:text-white transition-all rounded-lg" onClick={() => handleMailToClient('first')} />
              <Button type="button" label="Use in Mailbox" className="bg-purple text-white rounded-lg" icon="pi pi-envelope" onClick={() => handleSendMail('followup')} />
              <Button disabled={(rowData.follow_up_email_subject == data.follow_up_email_subject && rowData.follow_up_email_template == data.follow_up_email_template)}
                type="button" label="Save Template" loading={saveTemplateFollowupLoading} className="bg-green-500 text-white rounded-lg border-green-500" onClick={() => handleSaveTemplate('followup')} />
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Generated Email Templates',
      content: (
        <>
          <Table
            checkbox={true} columns={emailTemplates} 
            data={rowData.email_templates}
            actions={true} customActions={emailTemplateCustomActions} actionsWidth={'25rem'}
            paginator={false} 
          />
        </>
      )
    }
  ].filter(Boolean)

  const toast = useRef<Toast>(null);

  useEffect(() => {
    // setIsUnlocking(null);
    console.log('rowData ::: ', rowData)
    axiosService.api.get(`/api/contact/get_available_contact_person_count/${rowData.id}`)
    .then((response:any) => {
      if(response.data.status){
        setAvailableContactPersonCount( response.data.data );        
      }
    }).catch((error:any) => {
      console.log(error);
    });
  }, [rowData]);
  

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <TabView pt={{ nav: { className: 'bg-purple-light rounded-tr-lg rounded-tl-lg' } }} activeIndex={activeIndex} onTabChange={(e: any) => { setActiveIndex(e.index) }}>
        {
          tabPanels.map((e: any, index: number) => {
            return (
              <TabPanel key={index} header={e.title} pt={{ headerTitle: {className: 'font-normal ' + (activeIndex == index ? 'text-purple' : 'text-label')}, headerAction: { className: `${index == 0 ? 'rounded-tl-lg' : ''} border-purple-highlight ${activeIndex == index ? 'border-b-purple' : 'bg-purple-light'}` } }}>
                {e.content}
              </TabPanel>
            );
          })
        }

      </TabView>
      <SendMailModal visible={mailModalShow} data={mailModalData} onHide={() => setMailModalShow(false)} callback={handleCallback}/>
      <LeadMailSystemModal visible={mailSystemModal} data={mailSystemData} templateData={rowData} onHide={() => setMailSystemModal(false)}  callback={handleCallback}/>
    </>
  )
};

export default ExpandedLeads;
