import { useEffect, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { HubSpot } from "../../../components/Icons/ImgPath";
import { useAxios } from "../../../contexts/AxiosContext";

const SuperAdminCRM: React.FC<any> = ({ setLoadingState }) => {
  const axiosService = useAxios();
  // const buttonRef = useRef<any>(null);

  const [crms, setCrms] = useState<any>([]);
  const [connectedCrm, setConnectedCrm] = useState<any>({
    hubspot: false,
    zoho: false,
    salesforce: false
  });
  const updateCrmConnection = (crmType:string, isConnected: boolean) => {
    setConnectedCrm((prevCrm:any) => ({
      ...prevCrm, // Keep the previous state
      [crmType]: isConnected, // Update the specific CRM's status
    }));
  };

  const [activeUrl, setActiveUrl] = useState(null);
  const [currentCrmType, setCurrentCrmType] = useState<string | null>(null);

  const [receivedMessage, setReceivedMessage] = useState<any>(null);

  const openPopup = (url: string, crmType: string) => {
    const popup = window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    if (!popup) {
      console.error("Popup failed to open. Ensure pop-ups aren't blocked.");
    } else {
      // Check if the popup is closed every 500ms
      const checkPopup = setInterval(() => {
        
        if (popup.closed) {
          clearInterval(checkPopup); // Stop checking
          setCrms((prevCrms:any) =>
            prevCrms.map((crm:any) => 
              crm.type === crmType ? { ...crm, status: 'Connect' } : crm
            )
          );
          setActiveUrl(null);
          setCurrentCrmType(null);
          console.log("receivedMessage", receivedMessage);
        }
      }, 500); // Check every half second
    }
  };

  useEffect(()=>{
    setLoadingState(false)
  })

  useEffect(() => {
    if (activeUrl && currentCrmType) {
      openPopup(activeUrl, currentCrmType);
    }
  }, [activeUrl, currentCrmType]);
  
  const handleButtonOnClick = (type: any) => {
    setCrms((prevCrms:any) => prevCrms.map((crm:any) => crm.type === type ? { ...crm, status: 'Connecting' } : crm ));
  
    axiosService.api.get(`/api/linked-account/${type}`)
    .then((response: any) => {
      setActiveUrl(response.data);
      setCurrentCrmType(type);
    })
    .catch((error:any) => {
      console.log(error);
    });
  };

  const handleMessage = useCallback((event: MessageEvent) => {
    const { data } = event;
    if (data?.type && data.response?.status) {
      setReceivedMessage(data.response);
    }
  }, []);
  
  useEffect(() => {
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (receivedMessage) {
      if(receivedMessage.status){
        updateCrmConnection(receivedMessage.type, true);
        setReceivedMessage(null);
      }
    }
  }, [receivedMessage]);

  const crmsList = [
    { 
      id: 1, 
      type: 'hubspot',
      logo: HubSpot, 
      status: 'Connect',
    }
  ];

  // const [linkedAccounts, setLinkedAccounts] = useState();
  const getConnectedAccounts = () => {
    axiosService.api.get('/api/superadmin/linked-accounts').then((response: any) => {
      // const connectedAccounts = response.data;

      const updatedCrms = crmsList.map((crm:any) => {
        const isConnected = response.data.some((account:any) => account.provider.name.toLowerCase() === crm.type);
        return {
          ...crm,
          status: isConnected ? 'Connected' : 'Connect'
        };
      });
      console.log("connectedAccounts::", response.data);

      // setLinkedAccounts(connectedAccounts);
      setCrms(updatedCrms);

    }).catch((error: any) => {
      console.log(error);
    });
  }


  useEffect(() => {
    setCrms(crmsList);
    getConnectedAccounts();
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Superadmin CRM</h1>
      </div>
      <div className="panel bg-white rounded-lg p-5">
        <h1 className="text-2xl mb-4 text-center">Connect Your CRM</h1>
        <div className="flex gap-4">
            {crms.map((step:any, i:number) => (
              <div key={i} className="rounded-lg border-purple-highlight border p-4 items-center justify-between flex w-1/4">
                <img src={step.logo} alt={step.type} className="h-20 w-20 object-contain" />
                <Button
                  onClick={() => handleButtonOnClick(step.type)}
                  className={`p-3 rounded-full  ${connectedCrm[step.type] || step.status == 'Connected' ? 'border-[#1BA750] bg-[#1BA750]' :' border-purple bg-purple'}`}
                  label={connectedCrm[step.type] ? 'Connected' : (step.status ? step.status : 'Connect')}
                  loading={(step.status==="Connecting")}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminCRM;