import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Menu} from 'primereact/menu';
import ComposeMail from './ComposeMail';
import ReadMail from './ReadMail';
import { Dropdown } from 'primereact/dropdown';
import { useToast } from '../../contexts/ToastContext';
import LazyTable from '../../components/tables/LazyTable';
import SkeletonTable from '../../components/tables/SkeletonTable';
import { template } from 'lodash';
import { Tooltip } from 'primereact/tooltip';

const Inbox: React.FC<any> = ({ axiosService, setLoadingState }) => {
  const [view, setView] = useState('Inbox')
  const location = useLocation();
  const { showToast } = useToast();
  const mailbox_id = new URLSearchParams(location.search).get("mailbox_id");
  const [currentMailbox, setCurrentMailbox] = useState<any>(mailbox_id);
  const [emailType, setEmailType] = useState('Inbox');
  const [mailView, setMailView] = useState<any>();

  const itemRenderer = (item:any,options:any) => {
    return (
      <div className='p-menuitem-content'>
        <button 
          className="flex align-items-center p-menuitem-link" 
          onClick={options.onClick} 
          aria-label={item.label}
        >
          <span className={item.icon} />
          <span className="hidden md:flex mx-2">{item.label}</span>
        </button>
      </div>
    )
  };

  let items = [
    { label: 'Inbox', icon: 'pi pi-inbox', template: itemRenderer, command: () => {
      setEmailType('Inbox');
      getGmailMessages(currentMailbox, 'inbox');
      setView('Inbox');
    }},
    { label: 'Sent', icon: 'pi pi-send', template: itemRenderer, command: () => {
      setEmailType('Sent');
      getGmailMessages(currentMailbox, 'sent');
      setView('Inbox'); 
    }},
    { label: 'Scheduled', icon: 'pi pi-calendar-clock', template: itemRenderer, command: () => {
      setEmailType('Scheduled');
      getGmailMessages(currentMailbox, 'scheduled');
      setView('Inbox'); 
    }},
  ];

  const messageShortTemplate = (data: any) => {
    return <div dangerouslySetInnerHTML={{ __html: data }} /> 
  }

  const toTemplate = (data: any, rowData: any, field: any) => {
    if(rowData.schedule_date != null) {
      return <div className='flex items-center gap-4'>
        {data} <i className={`pi pi-calendar-clock text-green-900 g${rowData.id}`}></i> <Tooltip target={`.g${rowData.id}`}>Scheduled Mail<br/>Sent at: {scheduleDateTemplate(rowData.scheduled_sent_at)}</Tooltip>
      </div>
    }
    return data;
  }

  const columns:any = [
    { field: 'from', header: 'From' , headerClassName:'w-[20%]'},
    { field: 'subject', header: 'Subject'},
    { field: 'message_short', header: 'Message', hasTemplate: true, template: messageShortTemplate},
  ];

  const sentColumns:any = [
    { field: 'to', header: 'To' , headerClassName:'w-[20%]', hasTemplate: true, template: toTemplate},
    { field: 'subject', header: 'Subject'},
    { field: 'message_short', header: 'Message', hasTemplate: true, template: messageShortTemplate},
  ];  

  const scheduleDateTemplate = (data: any) => {
    const dateInUTC = new Date(data + ' UTC');
    return dateInUTC.toLocaleString('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit',
      hour12: true,
    })
  }

  const scheduleColumns:any = [
    { field: 'schedule_date', header: 'Schedule Date', hasTemplate: true, template: scheduleDateTemplate },
    { field: 'to', header: 'To' , headerClassName:'w-[20%]'},
    { field: 'subject', header: 'Subject'},
    { field: 'message_short', header: 'Message', hasTemplate: true, template: messageShortTemplate},
    { field: 'remarks', header: 'Remarks'},
  ];

  const handleReadMail = (id: string) => {
    setLoadingState(true);
    axiosService.api.get('/api/mail/get/'+id)
    .then((response:any) => {
      setMailView(response.data);
      setLoadingState(false);
      setView('Read');

    })
    .catch((error:any) => {
      console.log('Error fetching data:', error);
      setLoadingState(false);
    });


    // const mail = data.filter((mail: any) => (mail.id === id))[0];
    console.log('id :: ', id);
  };

  const [mailboxes, setMailboxes] = useState<any>([]);
  const getMailbox = () => {
    setLoadingState(true);
    const newMailbox = { id: '2147483647', email: 'SectorSift (Default)' };
    setMailboxes([newMailbox]);
    axiosService.api.get('/api/mailboxes/all')
    .then((response:any) => {
      const modifiedData = response.data.map((item: any) => ({
        ...item,
        email: `${item.email} (${item.type})`
      }));
      setMailboxes((prevMailboxes: any) => [...modifiedData, ...prevMailboxes]);
      // setMailboxes((prevMailboxes:any) => [...response.data, ...prevMailboxes]);
      setLoadingState(false);
      // if(response.data.length < 1){
      //   showToast({
      //     severity: 'warn',
      //     summary: 'Warning!',
      //     detail: 'We suggest that you set up your mailbox before using this feature 😊️',
      //   });
      //   navigate(`/main?page=mailbox`);
      // }
    })
    .catch((error:any) => {
      console.log('Error fetching data:', error);
      setLoadingState(false);
    });
    setCurrentMailbox(mailbox_id);
  }

  const [refreshTable, setRefreshTable] = useState(false);
  const [api, setApi] = useState<any>();
  const [apiAll, setApiAll] = useState<any>();

  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  const getGmailMessages = (mailbox_id: any, type:any = 'inbox', from_dropdown:boolean = false) => {
    if(!mailbox_id) { return; }
    const view = capitalizeFirstLetter(type);
    setEmailType(view);
    setView('Inbox');
    // setRefreshTable(true);
    // setLoadingState(true);
    if(mailbox_id){
      const apiUrl = `/api/mail/get/${type}/${mailbox_id}/paginate`;
      const apiAll = `/api/mail/get/${type}/${mailbox_id}/select-all`;
      setApi(apiUrl);
      setApiAll(apiAll);
      setRefreshTable(from_dropdown);
    } else {
      // setLoadingState(false);
    }
  }

  const handleDataConvert = (data: any, setTableData: any) => {
    const modifiedData = data.map((mail: any) => {
      const maxLength = 100; // Maximum length for message
      let messageShort = mail.message;
      if (messageShort && messageShort.length > maxLength) {
        const lastEllipsisIndex = messageShort.lastIndexOf('...', maxLength);
        if (lastEllipsisIndex !== -1) {
          messageShort = messageShort.substring(0, lastEllipsisIndex + 3);
        } else {
          messageShort = messageShort.substring(0, maxLength) + '...';
        }
      }
      return {
        ...mail,
        message_short: messageShort
      };
    });
    setTableData(modifiedData);
  }

  const handleDropdownOnChange = (e: any) => {
    setCurrentMailbox(e.value.id)
    getGmailMessages(e.value.id, emailType.toLowerCase(), true);
  }

  const handleActionsClicked = (id:any, type: any) => {
    if(type === "view"){
      handleReadMail(id);
    }
  }

  const handleBulkDelete = (selectedData: any) => {
    setLoadingState(true);
    const ids = selectedData.map((e: any) => e.id);
    // console.log(ids);
    axiosService.api.post('/api/mail/bulk-delete', {ids: ids})
    .then((response:any) => {
      setLoadingState(false);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
      setRefreshTable(true);
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });

  }

  const handleSendNow = (selectedData: any) => {
    setLoadingState(true);
    const ids = selectedData.map((e: any) => e.id);
    axiosService.api.post('/api/mail/send-now', {ids: ids})
    .then((response:any) => {
      setLoadingState(false);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
      setRefreshTable(true);
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });
  }

  useEffect(() => {
    getMailbox();
    // getGmailMessages(currentMailbox);
  }, []);

  useEffect(() => {
    if(mailboxes.length != 0){
      setCurrentMailbox(mailboxes[0].id);
    }
  }, [mailboxes]);

  useEffect(() => {
    if(currentMailbox != '2147483647') {
      getGmailMessages(currentMailbox, emailType.toLowerCase());
    }
  }, [currentMailbox]);
  
  return(
  <>
  <div id="leads" className="flex flex-col gap-3">
    <div className="title flex justify-between items-center">
      <h1 className="text-2xl">Emails</h1>
      <div className='flex gap-2 items-center'>
        <label className='text-label'>Mailbox</label>
        <Dropdown value={mailboxes.filter((e:any) => e.id === currentMailbox || mailbox_id )[0]} onChange={handleDropdownOnChange} options={mailboxes} optionLabel="email" 
          placeholder="Select Mailbox" className={`currentMailbox-${currentMailbox} w-full md:w-14rem rounded-lg border border-gray`} />
      </div>
      {/* <Button label="Add Email Adress" icon="pi pi-plus" onClick={() => setModalVisible(true)} className="bg-purple rounded-lg border-purple ring-0" /> */}
    </div>
    <div className="panel bg-white rounded-lg p-5 min-h-[50rem]">
      <div className='flex flex-col md:flex-row gap-4'>
        <div className='w-[100%] md:w-[20%]'>
          <button aria-label="Compose New" onClick={() => setView('Compose')} className="mb-5 w-full p-button-outlined p-button p-component">
            <span className="p-button-label p-c" data-pc-section="label">Compose New</span>
          </button>
          <Menu className='flex w-full overflow-x-auto' model={items} pt={{menu: {
            className: 'flex flex-row md:flex-col w-full', // Add custom class to the <ul> element
          }}} onClick={(e:any) => console.log(e.target?.id)} />
        </div>
        <div className='flex flex-col w-full'>
          {view == 'Compose' && <ComposeMail setView={setView} mailboxes={mailboxes} currentMailbox={currentMailbox} />}
          {view == 'Inbox' && (
            <>
              <h1 className="text-2xl mb-3">{emailType}</h1>
              {
                !api && <SkeletonTable />
              }
              { api && (emailType.toLowerCase() === "inbox") && <LazyTable
                api={api} apiAll={apiAll} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable} setRefreshTable={setRefreshTable}
                hasOptions={true} checkbox={true} columns={columns} expandableRow={true} 
                actions={true} action_types={{view: true, delete: true}} actionsClicked={handleActionsClicked}
                bulkOptions={[
                  {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                ]}
              />}

              { api && (emailType.toLowerCase() === "sent") && <LazyTable
                api={api} apiAll={apiAll} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable} setRefreshTable={setRefreshTable}
                hasOptions={true} checkbox={true} columns={sentColumns} expandableRow={true} 
                actions={true} action_types={{view: true, delete: true}} actionsClicked={handleActionsClicked}
                bulkOptions={[
                  {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                ]}
              />}

              { api && (emailType.toLowerCase() === "scheduled") && <LazyTable
                api={api} apiAll={apiAll} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable} setRefreshTable={setRefreshTable}
                hasOptions={true} checkbox={true} columns={scheduleColumns} expandableRow={true} 
                actions={true} action_types={{view: true, delete: true}} actionsClicked={handleActionsClicked}
                bulkOptions={[
                  {name: 'Send Now', value: 'send_now', onSubmit: handleSendNow},
                  {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                ]}
              />}
{/* 
              { api && (emailType.toLowerCase() === "sent")  && <LazyTable
                api={api} apiAll={apiAll} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable && (emailType.toLowerCase() === "sent")} setRefreshTable={setRefreshTable}
                hasOptions={true} checkbox={true} columns={columns} expandableRow={true} 
                actions={true} action_types={{view: true, delete: true}} actionsClicked={handleActionsClicked}
                bulkOptions={[
                  {name: 'Delete', value: 'delete', onSubmit: handleBulkDelete}
                ]}
              />} */}
            </>
          ) }
          {view == 'Read' && <ReadMail setView={setView} data={mailView} axiosService={axiosService} currentMailbox={currentMailbox}/>}
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default Inbox;