import { useEffect, useState } from "react";
import ExpandedLeads from "./ExpandedLeads";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import LeadModal from "../../components/modals/leads/LeadModal";
import { Dropdown } from "primereact/dropdown";
import LazyTable from "../../components/tables/LazyTable";
import { useToast } from "../../contexts/ToastContext";
import Inputs from "../../components/forms/Inputs";
import SkeletonTable from "../../components/tables/SkeletonTable";
import { Tooltip } from "primereact/tooltip";
import IconMap from "../../components/Icons/IconMaps";
import LeadScheduleEmail from "../../components/modals/leads/LeadScheduleEmail";
import LeadDealStatus from "../../components/modals/leads/LeadDealStatus";
import _ from 'lodash';

const Leads: React.FC<any> = ({ axiosService, setLoadingState, setRefreshAccount }) => {
  const [activeAgent, setActiveAgent] = useState<any>();
  const { showToast, clearToast } = useToast();
  const [filterValues, setFilterValues] = useState<any>({
    date_range: null,
    first_email_status: 'All',
    follow_up_status: 'All',
    needs_attention: 'Any',
    needs_attention_followup: 'Any',
    contacts_discovered: [0,200],
    lead_contact: ''
  });
  const [tabidk, setTabidk] = useState('tabidk');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const replyStatusTemplate = (data:string, rowData: any) => {
    const status = rowData.main_contact_has_reply > 0 ? true : false;
    let classname = "bg-purple-highlight text-purple";
    let icon = "pi-pause-circle";
    let text = rowData.main_contact_has_reply > 0 ? 'Replied' : 'Waiting';
    if(status) {
      classname = "bg-lightgreen text-green-900";
      icon = 'pi-check-circle';
    } else {
      classname = "bg-orange-200 text-orange-900";
      icon = 'pi-hourglass';
    }

    return (
      <div className="flex gap-2 items-center">
        <span className={`${classname} py-1 px-2 rounded-md flex gap-2 items-center`}>
          <i className={`pi ${icon}`}></i>{text}
        </span>
      </div>
    );
  };
  const emailStatusTemplate = (data: string, rowData: any, field: any) => {
    const uniqueId = (field + Math.random().toString(36).substring(2, 9));
    const status = data.toLowerCase();
    let classname = "bg-purple-highlight text-purple";
    let icon = "pi-pause-circle";
    if(status === 'sent' || status === 'completed') {
      classname = "bg-lightgreen text-green-900";
      icon = (status === "sent") ? 'pi-envelope' : 'pi-check-circle';
    } else if(status === 'in progress' || status === 'scheduled') {
      classname = "bg-orange-200 text-orange-900";
      icon = (status === "in progress") ? 'pi-hourglass' : 'pi-calendar-clock';
    }

    return (
      <div className="flex gap-2 items-center">
        <span className={`${classname} py-1 px-2 rounded-md flex gap-2 items-center`}>
          <i className={`pi ${icon}`}></i>{data}
        </span>
        {
          rowData && rowData.first_email_needs_attention === 1 && field === "first_email_status" && (
            <span className="">
              <i className={`${uniqueId} pi pi-info-circle text-red-500`}></i>
              <Tooltip target={`${'.' + uniqueId}`} content={"First email template needs attention!"}/>
            </span>
          )
        }
        {
          rowData && rowData.follow_up_email_needs_attention === 1 && field === "follow_up_status" && (
            <span className="">
              <i className={`${uniqueId} pi pi-info-circle text-red-500`}></i>
              <Tooltip target={`${'.' + uniqueId}`} content={"Follow-up email template needs attention!"}/>
            </span>
          )
        }
      </div>
    )
  };
  const leadsFilter = (
    <>
      <div>
        <Inputs type="date-range" 
          onChange={(e:any) => {
            setFilterValues((prevValues:any) => ({
              ...prevValues, 
              date_range: e.value
            }));
          }} 
          value={filterValues.date_range} 
          label={"Discovered On"} 
        />
      </div>
      <div>
        <Inputs 
          value={filterValues.first_email_status} 
          options={[
            {value: 'All'},
            {value: 'Pending'},
            {value: 'In Progress'},
            {value: 'Scheduled'},
            {value: 'Completed'}
          ]} 
          optionLabel={'value'}
          label={"First Email Status"}
          type={"dropdown"} 
          onChange={(e:any) => (setFilterValues((prevValues:any) => ({...prevValues, first_email_status: e.value})))}
        />
      </div>
      <div>
        <Inputs 
          value={filterValues.follow_up_status} 
          options={[
            {value: 'All'},
            {value: 'Pending'},
            {value: 'In Progress'},
            {value: 'Scheduled'},
            {value: 'Completed'}
          ]} 
          optionLabel={'value'} 
          label={"Follow Up Status"} 
          type={"dropdown"} 
          onChange={(e:any) => (setFilterValues((prevValues:any) => ({...prevValues, follow_up_status: e.value})))}
        />
      </div>
      <div>
        <Inputs 
          value={filterValues.needs_attention} 
          options={[
            {value: 'Any'},
            {value: 'Yes'},
            {value: 'No'},
          ]} 
          optionLabel={'value'} 
          label={"Needs Attention First Email"}
          type={"dropdown"} 
          onChange={(e:any) => (setFilterValues((prevValues:any) => ({...prevValues, needs_attention: e.value})))}
        />
      </div>

      <div>
        <Inputs 
          value={filterValues.needs_attention_followup} 
          options={[
            {value: 'Any'},
            {value: 'Yes'},
            {value: 'No'},
          ]} 
          optionLabel={'value'} 
          label={"Needs Attention Follow-up Email"}
          type={"dropdown"} 
          onChange={(e:any) => (setFilterValues((prevValues:any) => ({...prevValues, needs_attention_followup: e.value})))}
        />
      </div>

      <div>
        <Inputs 
          value={filterValues.contacts_discovered} 
          label={"Contacts Discovered"} 
          type={"slider"} 
          onChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, contacts_discovered: e.value}))}}
          inputOnChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, contacts_discovered: e.target.value.split(',')}))}}
        />
      </div>
      {/* <div>
        <Inputs 
          value={filterValues.lead_contact} 
          label={"Lead Contact"} 
          type={"text"} 
          placeholder="e.g John Doe"
          onChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, lead_contact: e.target.value}))}}
          // inputOnChange={(e:any) => {setFilterValues((prevValues:any) => ({...prevValues, lead_contact: e.target.value.split(',')}))}}
        />
      </div> */}
    </>
  );

  const dealStatusTemplate = (data: string) => {
    return <>
      {data || 'New'}
    </>
  };

  // const unlockLead = (id:number) => {
  //   // setLoadingState(true);
  //     setRefreshTable(true);
  //   showToast({
  //     severity: 'warn',
  //     summary: 'Unlocking Lead Contacts!',
  //     detail: "Please hold on while we unlock your contacts for this lead.",
  //   });
  //   axiosService.api.get(`/api/leads/unlock/${id}`)
  //     .then((response:any) => {
  //       setRefreshTable(false);
  //       // setLoadingState(false);
  //       showToast({
  //         severity: 'success',
  //         summary: 'Lead Contacts Unlocked!',
  //         detail: "You have unlocked your lead contacts!",
  //       });
  //     }).catch((error:any) => {
  //       // setLoadingState(false);
  //     });
  // };

  // const unlockLeadTemplate = (data:number, rowData:any) => {
  //   return (
  //     <>
  //       {
  //         data === 1 ?
  //           <label htmlFor="">Available</label>
  //         :
  //           <Button 
  //             onClick={() => unlockLead(rowData.id)}
  //             label="Unlock Lead" 
  //             className="bg-purple rounded-lg border-purple ring-0 text-[15px]" 
  //             icon="pi pi-lock-open"
  //             iconPos="right"
  //           />
  //       }
  //     </>
  //   );
  // };

  const crmTemplate = (data: number, rowData: any) => {
    const iconDetails:any = {
      colors: {
        hubspot: 'Gray',
        zoho: 'Gray',
        salesforce: 'Gray',
      },
      messages: {
        hubspot: 'Lead has not yet been exported to hubspot',
        zoho: 'Lead has not yet been exported to zoho',
        salesforce: 'Lead has not yet been exported to salesforce',
      },
    };
  
    const updateIconDetails = (platform: string, notExportedCount: number) => {
      if (rowData[`${platform}_company`] != null) {
        if (notExportedCount == 0) {
          iconDetails.colors[platform] = 'Green';
          iconDetails.messages[platform] = 'All contacts have been exported.';
        } else {
          iconDetails.colors[platform] = 'Orange';
          iconDetails.messages[platform] = 'Some contacts have not yet been exported.';
        }
      }
    };
  
    const platforms = [
      { platform: 'hubspot', icon: 'FaHubspot' },
      { platform: 'zoho', icon: 'SiZoho' },
      { platform: 'salesforce', icon: 'LiaSalesforce' },
    ];
  
    platforms.forEach(({ platform }) => {
      const notExportedCount = rowData.contacts.filter(
        (contact: any) =>
          contact[`${platform}_contact_id`] == null &&
          contact.unlocked == 1 &&
          contact.apollo_person_id != null
      ).length;
      updateIconDetails(platform, notExportedCount);
    });
  
    const icons = (
      <div className="flex flex-col space-y-1">
        {platforms.map(({ platform, icon }) => (
          <span key={platform} className={`flex justify-center ${platform}-${rowData.id}`}>
            {IconMap(icon, null, { color: iconDetails.colors[platform] }, 32)}
            <Tooltip
              target={`.${platform}-${rowData.id}`}
              content={iconDetails.messages[platform]}
            />
          </span>
        ))}
      </div>
    );
    return icons;
  };
  

  const contactsDiscoveredTemplate = (data:number, rowData:any) => {
    return rowData.contacts.length;
  }

  const columns = [
    // { field: 'id', header: 'Code' },
    { field: 'company_name', header: 'Company Name', has_expander: true, has_logo: true },
    { field: 'first_email_status', header: 'First Email Status', template: emailStatusTemplate, hasTemplate: true },
    { field: 'follow_up_status', header: 'Follow-Up Status', template: emailStatusTemplate, hasTemplate: true },
    { field: 'main_contact_has_reply', header: 'Reply Status', template: replyStatusTemplate, hasTemplate: true },
    { field: 'contacts_discovered_count', header: 'Contacts Discovered', template: contactsDiscoveredTemplate, hasTemplate: true },
    { field: 'main_contact_name', header: 'Lead Contact' },
    { field: 'biography_short', header: 'Biography' },
    { field: 'deal_status', header: 'Deal Status', template: dealStatusTemplate, hasTemplate: true },
    { field: 'discovered_on', header: 'Discovered On' },
    { field: 'hubspot_company', header: "CRM's", template: crmTemplate, hasTemplate: true },
    // { field: 'unlocked', header: 'Unlocked', template: unlockLeadTemplate, hasTemplate: true }, 
  ];

  // const handleActionsClicked = (data: any, type: any) => {
  //   // console.log('data', data, type);
  // };

  const handleRowExpand = (event: any) => {
    // console.log('expanded');
  };

  const handleRowCollapse = (event: any) => {
    // console.log('collapsed');
  };

  const [api, setApi] = useState<any>();
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const getData = (id: any) => {
    setApi('/api/leads/paginate/'+id);
    // setRefreshTable(true);
  };

  const handleDataConvert = (data: any, setTableData: Function) => {
    const modifiedData = data.map((lead: any) => {
      const maxLength = 80; // Maximum length for biography_short
      var biographyShort = lead.biography;
      if (biographyShort.length > maxLength) {
        const lastEllipsisIndex = biographyShort.lastIndexOf('...', maxLength);
        if (lastEllipsisIndex !== -1) {
          biographyShort = biographyShort.substring(0, lastEllipsisIndex + 3);
        } else {
          biographyShort = biographyShort.substring(0, maxLength) + '...';
        }
      }
      const contacts_discovered = (lead.main_contact_id !== null ? 1 : 0);
      return {
        ...lead,
        biography_short: biographyShort,
        contacts_discovered_count: lead.contacts.length + contacts_discovered,
        main_contact_name: (lead.main_contact && lead.main_contact.name) ?? '---'
      };
    });
    setTableData(modifiedData);
  };

  const lead = {
    id: '',
    company_name: '',
    website: '',
    biography: '',
    first_email_subject: '',
    first_email_template: '',
    follow_up_email_subject: '',
    follow_up_email_template: '',
  }
  const [leadData, setLeadData] = useState<any>(lead);
  const [leadModal, setLeadModal] = useState(false);
  const [leadModalType, setLeadModalType] = useState('');
  const handleLeadModal = (type: string) => {
    setLeadModal(true);
    setLeadModalType(type);
  }

  const handleLeadModalClose = () => {
    setLeadData(lead);
    setLeadModal(false);
    setLeadModalType('')
  }

  const handleLeadData = (e:any) => {
    const { name, value } = e.target;
    setLeadData((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleLeadSubmit = () => {

    showToast({
      severity: 'info',
      summary: 'Processing',
      detail: 'Please wait...'
    });

    setIsSubmitting(true);

    let requestPromise;
    
    if (leadModalType === "edit") {
      requestPromise = axiosService.api.patch('/api/leads/update/' + leadData.id, leadData);
    } else {
      return;
    }
    requestPromise.then((response: any) => {
      // handleLeadModalClose();
      setIsSubmitting(false);
      getData(activeAgent.id);
      setRefreshTable(true);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    })
    .catch((error: any) => {
      setIsSubmitting(false);
      setLeadData((prevState:any) => ({
        ...prevState,
        errors: error.response.data.errors
      }));
    });
  }
  

  const deleteLead = (id: number) => {
    axiosService.api.delete('/api/leads/delete/' + id)
    .then((response:any) => {
      handleLeadModalClose();
      getData(activeAgent.id);
      setRefreshTable(true);
    })
    .catch((error:any) => {
      console.error('Error fetching data:', error);
    });
  }

  const confirmLeadDelete = (id: number) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteLead(id),
    });
  };

  const actionsClicked = (data: any, type: string) => {
    if(type === 'edit'){
      setLoadingState(true);
      axiosService.api.get('/api/leads/get/' + data)
      .then((response:any) => {
        setLoadingState(false);
        handleLeadModal('edit');
        setLeadData(response.data);
      })
      .catch((error:any) => {
        setLoadingState(false);
        console.error('Error fetching data:', error);
      });
    } else if(type==='trash'){
      confirmLeadDelete(data);
    }
  }

  const [agents, setAgents] = useState<any>([]);
  const getAgents = () => {
    axiosService.api.get('/api/agents/all')
    .then((response:any) => {
      setAgents(response.data.map((e:any) => ({
        name: e.name,
        id: e.id
      })));
      
      if(response.data.length===0){
        setAgents([{id:0, name:''}]);
        setActiveAgent(agents[0]);
        if(agents[0] != null && agents[0] != undefined){
          getData(agents[0].id);
        }       
      }
      
      // setLoadingState(false);
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });
  }

  const handleAgentSelection = (value: any) => {
    setLoadingState(true);
    setActiveAgent(value);
    getData(value.id);
    setRefreshTable(true);
    setTabidk(_.uniqueId());
  }

  const handleBulkDelete = (selectedData: any) => {
    setLoadingState(true);
    const ids = selectedData.map((e: any) => e.id);
    axiosService.api.post('/api/leads/bulk-delete', {ids: ids})
    .then((response:any) => {
      setLoadingState(false);
      getData(activeAgent.id);
      setRefreshTable(true);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
      setRefreshTable(true);
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });

  }

  const handleBulkExportToHubspot = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);
    showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to Hubspot is in progress.`, sticky: true, loading: true, });
    // console.log('ids :: ', ids)
    axiosService.api.post('/api/leads/export-leads/hubspot', { leads: ids }).then((response:any)=>{
      getData(activeAgent.id);
      setRefreshTable(true);
      clearToast();
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    }).catch((error:any)=>{
      // console.log('error :: ', error.response.data.error)
      clearToast();
      showToast({ severity: 'error', summary: 'Unable to export!', detail: error.response.data.message });
    })
  }

  const handleBulkExportToZoho = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);
    showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to Zoho is in progress.`, sticky: true, loading: true, });
    // console.log('ids :: ', ids)
    axiosService.api.post('/api/leads/export-leads/zoho', { leads: ids }).then((response:any)=>{
      getData(activeAgent.id);
      setRefreshTable(true);
      clearToast();
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    }).catch((error:any)=>{
      // console.log('error :: ', error.response.data.error)
      clearToast();
      showToast({ severity: 'error', summary: 'Unable to export!', detail: error.response.data.message });
    })
  }

  const handleBulkExportToSalesforce = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);

    // showToast({ severity: 'warn', summary: 'Still Developing', detail: `This feature is still in development.` });
    showToast({ severity: 'info', summary: 'Please wait!', detail: `Export to Salesforce is in progress.`, sticky: true, loading: true, });
    console.log('ids :: ', ids)
    axiosService.api.post('/api/leads/export-leads/salesforce', { leads: ids }).then((response:any)=>{
      getData(activeAgent.id);
      setRefreshTable(true);
      clearToast();
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
      }
    }).catch((error:any)=>{
      // console.log('error :: ', error.response.data.error)
      clearToast();
      showToast({ severity: 'error', summary: 'Unable to export!', detail: error.response.data.message });
    })
  }



  const [scheduleMailModal, setScheduleMailModal] = useState(false);
  const [scheduleMailIds, setScheduleMailIds] = useState<any>();
  const handleBulkScheduleEmail = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);
    setScheduleMailModal(true);
    setScheduleMailIds(ids);
  }

  const [dealStatusModal, setDealStatusModal] = useState(false);
  const [dealStatusIds, setDealStatusIds] = useState<any>();
  const handleBulkDealStatus = (selectedData: any) => {
    const ids = selectedData.map((e: any) => e.id);
    setDealStatusModal(true);
    setDealStatusIds(ids);
  }

  const handleDealStatusOnSubmit = (data: any) => {
    axiosService.api.post('/api/leads/bulk-update/deal-status', data)
    .then((response:any) => {
      setLoadingState(false);
      getData(activeAgent.id);
      setRefreshTable(true);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
        setRefreshTable(true);
      } else {
        showToast({
          summary: 'Error!',
          severity: 'error',
          detail: response.data.message,
        });
      }
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });
  }

  const handleScheduleMailOnSubmit = (data: any) => {
    const updatedData = {
      ...data,
      schedule_date: new Date(data.schedule_date),
      schedule_time: new Date(data.schedule_time),
    };


    setLoadingState(true);
    showToast({
      summary: 'Processing',
      severity: 'info',
      detail: 'Please wait while we schedule your emails.',
    });
    axiosService.api.post('/api/mail/bulk-schedule-send', {ids: scheduleMailIds, data: updatedData})
    .then((response:any) => {
      getData(activeAgent.id);
      setRefreshTable(true);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
        setRefreshTable(true);
      } else {
        showToast({
          summary: 'Error!',
          severity: 'error',
          detail: response.data.message,
        });
      }
      setLoadingState(false);
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });

  }

  const leadModalCallback = () => {
    setRefreshTable(true);
  }

  const dealStatusCallback = (dealStatus: any, lead_id: any) => {
    axiosService.api.patch('/api/leads/change-deal-status/'+lead_id, {status: dealStatus})
    .then((response:any) => {
      setLoadingState(false);
      if(response.data.status){
        showToast({
          summary: 'Success',
          severity: 'success',
          detail: response.data.message,
        });
        setRefreshTable(true);
      } else {
        showToast({
          summary: 'Error!',
          severity: 'error',
          detail: response.data.message,
        });
      }
    })
    .catch((error:any) => {
      setLoadingState(false);
      console.error('Error fetching data:', error);
    });
  }

  useEffect(() => {
    getAgents();
  }, []);

  useEffect(() => {
    if(agents.length != 0){
      setActiveAgent(agents[0]);
      if(agents[0] != null && agents[0] != undefined){
        getData(agents[0].id);
        // setApi('/api/leads/paginate/'+agents[0].id);
      }
    }
  }, [agents]);

  const leadExportHeaders = [
    { label: 'Company Name', key: 'company_name'},
    { label: 'Website URL', key: 'website'},
    { label: 'Discovered On', key: 'discovered_on'},
    { label: 'First Email Status', key: 'first_email_status'},
    { label: 'Follow-up Email Status', key: 'follow_up_status'},
    { label: 'Contacts Discovered', key: 'contacts_discovered_num'},
    { label: 'Deal Status', key: 'deal_status'},
    { label: 'Biography', key: 'biography'},
    { label: 'Lead Contact Name', key: 'main_contact.name'},
    { label: 'Lead Contact Role', key: 'main_contact.role'},
    { label: 'Lead Contact Email', key: 'main_contact.email'},
    { label: 'Lead Contact Phone', key: 'main_contact.phone'},
    { label: 'Lead Contact LinkedIn Profile', key: 'main_contact.linkedin'},
    { label: 'First Email Subject', key: 'first_email_subject'},
    { label: 'First Email Template', key: 'first_email_template'},
    { label: 'Follow-up Email Subject', key: 'follow_up_email_subject'},
    { label: 'Follow-up Email Template', key: 'follow_up_email_template'},
  ]

  return (
    <div id="leads" className="flex flex-col gap-3">
      <div className="title flex justify-between items-center">
        <h1 className="text-2xl">Leads</h1>
        <div className="flex items-center gap-4">
          <label className="text-label">Campaign: </label>
          <Dropdown value={activeAgent} onChange={(e) => handleAgentSelection(e.value)} options={agents} optionLabel="name" 
            checkmark={true} highlightOnSelect={false} placeholder="Select an Agent" className="w-full md:w-14rem rounded-lg border border-gray" 
          />
        </div>
      </div>

      <div className="panel bg-white rounded-lg p-5">
        {
          !api && <SkeletonTable />
        }
        {
          api && <LazyTable
            api={api} apiAll={`/api/leads/${activeAgent.id}/select-all`} convertData={true} convertDataFunction={handleDataConvert} refreshTable={refreshTable} setRefreshTable={setRefreshTable} key={tabidk}
            hasOptions={true}
            checkbox={true}
            columns={columns}
            setLoadingState={setLoadingState}
            actions={true}
            action_types={{ edit: true, delete: true }}
            actionsClicked={actionsClicked}
            expandableRow={true}
            onRowExpand={handleRowExpand}
            onRowCollapse={handleRowCollapse}
            rowExpansionTemplate={(rowData: any) => <ExpandedLeads setRefreshAccount={setRefreshAccount} data={rowData} setRefreshTable={setRefreshTable} emailStatusFunction={emailStatusTemplate} callback={leadModalCallback} onDealStatusCallback={dealStatusCallback}/>}
            bulkOptions={[
              {name: 'Schedule Email', icon: IconMap('BiMailSend'), value: 'scheduleEmail', onSubmit: handleBulkScheduleEmail},
              {name: 'Update Deal Status', icon: IconMap('FaRegHandshake'), value: 'dealStatus', onSubmit: handleBulkDealStatus},
              {name: 'Export to HubSpot', icon: IconMap('FaHubspot'), value: 'hubspot', onSubmit: handleBulkExportToHubspot},
              {name: 'Export to Zoho', icon: IconMap('SiZoho'), value: 'zoho', onSubmit: handleBulkExportToZoho},
              {name: 'Export to Salesforce', icon: IconMap('LiaSalesforce'), value: 'salesforce', onSubmit: handleBulkExportToSalesforce},
              {name: 'Delete', icon: IconMap('FaTrashAlt'), value: 'delete', onSubmit: handleBulkDelete},
            ]}
            filters={leadsFilter}
            filterValues={filterValues}
            warnMessage={'Some leads may not be selected because they need attention.'}
            hasExport={true}
            exportHeaders={leadExportHeaders}
            exportOptions={
              {
                csv: true,
                // hubspot: true
              }
            }
            tableName="leads"
          />
        }
      </div>
      <LeadModal 
        data={leadData} 
        setData={handleLeadData} 
        visible={leadModal} 
        onSubmit={handleLeadSubmit} 
        onHide={() => {handleLeadModalClose()}} 
        callback={leadModalCallback}
        isSubmitting={isSubmitting}
      />
      <LeadScheduleEmail data={scheduleMailIds} visible={scheduleMailModal} onSubmit={handleScheduleMailOnSubmit} onHide={() => {setScheduleMailModal(false)}}/>
      <LeadDealStatus data={dealStatusIds} visible={dealStatusModal} onSubmit={handleDealStatusOnSubmit} onHide={() => {setDealStatusModal(false)}}/>
      <ConfirmDialog/>
    </div>
  );
};

export default Leads;
