import React, { useState, useEffect, useRef } from 'react';
import { Editor } from 'primereact/editor';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'quill/dist/quill.snow.css';
import { AiOutlinePaperClip } from 'react-icons/ai'; 
import Quill from 'quill';

const Link: any = Quill.import('formats/link');

class CustomLink extends Link {
  static create(value: any) {
    let node = super.create(value);
    node.setAttribute('data-src', value);
    return node;
  }

  static formats(domNode: any) {
    return domNode.getAttribute('data-src') || super.formats(domNode);
  }

  format(name: any, value: any) {
    if (name === 'data-src' && value) {
      this.domNode.setAttribute('data-src', value);
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(CustomLink, true);

const Requill: any = Quill;

Requill.import('ui/icons')['attachment'] = "<i class='pi pi-paperclip text-[14px] w-[14px] h-[14px]'>";

type InputProps = {
  value: any;
  name?: string;
  style?: any;
  onChange?: any;
};

const CustomEditor: React.FC<InputProps> = ({
  value,
  name,
  style,
  onChange,
}) => {
  const quillRef = useRef<any>(null);

  const fonts = [
    'Sans Serif', 'Serif', 'Fixed Width',
    'Comic Sans MS', 'Garamond', 'Georgia', 'Tahoma',
    'Trebuchet MS', 'Verdana',
  ];

  const fontNames = fonts.map((font: string) => font.replace(/\s+/g, '-'));

  useEffect(() => {
    const Font: any = Quill.import('formats/font');
    Font.whitelist = fontNames;
    Quill.register(Font, true);
  }, [fontNames]);

  // Image Handler with size restriction
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files ? input.files[0] : null;

      if (file) {
        const maxSize = 2 * 1024 * 1024; // 2 MB size limit
        if (file.size > maxSize) {
          alert('Image size should not exceed 2 MB.');
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          const quill = quillRef.current.getQuill();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  };

  // Document Handler to attach files like PDF, DOCX
  const documentHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.pdf,.doc,.docx,.txt'); // Accept only document types
    input.click();
  
    input.onchange = () => {
      const file = input.files ? input.files[0] : null;
  
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 5 MB size limit for documents
        if (file.size > maxSize) {
          alert('Document size should not exceed 5 MB.');
          return;
        }
  
        const reader = new FileReader();
        reader.onload = () => {
          const quill = quillRef.current.getQuill();
          const range = quill.getSelection();
          const fileName = file.name;
  
          // Generate base64 string
          const base64String = reader.result;
  
          // Construct custom HTML to be inserted into the editor
          const customHtml = `<a href="#" class="custom-file-link" data-src="${base64String}" target="_blank">${fileName}</a>`;
          // Use `dangerouslyPasteHTML` to insert custom HTML
          quill.clipboard.dangerouslyPasteHTML(range.index, customHtml);
        };
  
        reader.readAsDataURL(file);
      }
    };
  };  

  const toolbarOptions = [
    [{ font: fontNames }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image', 'video', 'code', 'attachment'], // Add an attachment button
    ['clean'],
  ];

  return (
    <Editor
      value={value}
      name={name}
      onTextChange={onChange}
      style={style ? style : { height: '120px' }}
      modules={{
        toolbar: {
          container: toolbarOptions,
          handlers: {
            image: imageHandler, // Custom image handler
            attachment: documentHandler, // Custom document handler
          },
        },
      }}
      className="custom-editor"
      ref={quillRef}
    />
  );
};

export default CustomEditor;
